import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Payment(props) {
    library.add( faArrowLeft, faAngleRight );
    const navigate = useNavigate();

    const [cartList, setCartList] = useState('');
    const [checkedAnimate, setCheckedAnimate] = useState(false)

    useEffect(()=>{
        const tokenString = sessionStorage.getItem('user_id');
        const formData = new FormData();
        formData.append('user_id', tokenString);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        Promise.all([
            fetch(`${window.url}/cartDetails`, requestOptions),
        ]).then(([cartList])=>
            Promise.all([cartList.json()])
        ).then((data) => {
            setCartList(data[0]);
        })
    },[]); 

    function continueAction(){
        const tokenString = sessionStorage.getItem('user_id');
        const productArray = []
        cartList.cartDetails.forEach(function (element) { 
            productArray.push(
                {
                    'product_id' : element.id,
                    'qty' : element.qty,
                    'price' : element.final_price,
                }
            )
        })
        const data = {
            'user_id' : tokenString,
            // 'delivery_address' : deliverTo.props,
            'products' : productArray,
            "name" : props?.deliverTo?.name,
            "phone" : props?.deliverTo?.phone,
            "address_line_1" : props?.deliverTo?.address_line_1,
            "address_line_2" : props?.deliverTo?.address_line_2,
            "pin" : props?.deliverTo?.pin,
            "finalTotalPrice" : props?.finalTotalPrice,
            "coupon" : props?.couponCode,
        }
        const data2 = JSON.stringify(data)
        // console.log(data2)
        console.log(props?.couponCode)

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
                'Content-Type': 'application/json',
            },            
            body: data2
          };
          
          fetch(`${window.url}/addOrder`, requestOptions)
          .then((response) => response.json())
          .then(function(responce){
            console.log(responce)
            if(responce.msg === 'success'){
                setCheckedAnimate(true)  
            }  
        }) 
    }

    function cod (){
        /********/
    }




    return(
        <div className="paymentPage">
            <section className="productListHead">
                <Link className="backBttn"  onClick={() => navigate(0)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
                <h4 className="wishListTxt">Payment</h4>
            </section> 

            <div className="paymentTypeWrap">
                <label><input type="radio" name="radio" value="cod" onChange={cod} checked /> Cash on delivery</label>
            </div>

            {checkedAnimate ?
                <div className="wrapper">
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                    <h4>Order Placed</h4>
                    <Link to="/" className="backBttn">Back to home</Link>
                </div>:null
            }

            <div className="cartTotalDes">
                <h4>Total Price : <strong>{props?.finalTotalPrice}</strong></h4> 
                <button onClick={continueAction} className={cartList.total > 0 ? "cBttn show" : "cBttn"}>Pay now <FontAwesomeIcon icon="fa-angle-right" /></button>
            </div>            
        </div>
    )
}
export default Payment;