import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faAngleRight, faTrashCan, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuantityPicker } from 'react-qty-picker';
import { Oval } from 'react-loader-spinner';
import Payment from './Payment'
function Checkoutproduct() {
    const navigate = useNavigate();
    library.add( faArrowLeft, faAngleRight, faTrashCan, faPencil );

    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [cartList, setCartList] = useState('');
    const [shortAlert, setShortAlert] = useState(false);
    const [addAlert, setAddAlert] = useState(false);
    const [qtyValue, setQtyValue] = useState(1);
    const [deliverTo, setDeliverTo] = useState('');    
    const [finalTotalPrice, setFinalTotalPrice] = useState('');


    function afterUpdateCartList(){
        const tokenString = sessionStorage.getItem('user_id');
        if(tokenString == null){
            navigate('/app/login');
        }
        const formData = new FormData();
        formData.append('user_id', tokenString);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        Promise.all([
            fetch(`${window.url}/cartDetails`, requestOptions),
        ]).then(([cartList])=>
            Promise.all([cartList.json()])
        ).then((data) => {
            setCartList(data[0]);
            setFinalTotalPrice(data[0].total)
        })
    }

    const [loadingScreen, setLoadingScreen] = useState(false);

    useEffect(()=>{
        afterUpdateCartList()
        setLoadingScreen(true);
        setTimeout(function() {
          setLoadingScreen(false);
        }, 500);

        const tokenString = sessionStorage.getItem('user_id');
        if(tokenString == null){
            navigate('/app/login');
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
        };
        Promise.all([
            fetch(`${window.url}/fetchDeliveryAddress/${tokenString}`, requestOptions)
        ]).then(([deliveryAddress])=>
            Promise.all([deliveryAddress.json()])
        ).then((data) => {
            setDeliveryAddress(data[0]);
            setDeliverTo(data[0].locations[0]);
        })
        
    },[navigate]); 

    function changeLocation(location){
        setDeliverTo(location)
    }

    function swipeTodelete(cart_id){        
        const formData = new FormData();
            formData.append('id', cart_id);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${window.token}`,
                },
                body: formData
            };
          fetch(`${window.url}/removeFromCart`, requestOptions)
          .then((response) => response.json())
          .then(function(data){   
            if(data.error === 0){
                setShortAlert(true);
                setTimeout(function() {
                  setShortAlert(false);
                }, 2500);
                afterUpdateCartList()
            }         
          }) 
    }


    function qtyPicker(qtyValue, cart_id){
        setQtyValue(qtyValue);
        const formData = new FormData();
        formData.append('qty', qtyValue);
        formData.append('cart_id', cart_id);

        const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
        };
        
        fetch(`${window.url}/editCart`, requestOptions)
        .then((response) => response.json())
        .then(function(data){
          if(data.error === 0){
            afterUpdateCartList()  
          }  
        }) 
    }

    const [changeLocationPop, setChangeLocationPop] = useState(false)
    const [changeLocationPopAnimate, setChangeLocationPopAnimate] = useState('changeAddressInn')

    function changeDeliveryLocation (){
        setChangeLocationPop(true)
        setTimeout(function() {
            setChangeLocationPopAnimate('changeAddressInn open');
        }, 0);
    }

    function closePop(){
        setTimeout(function() {
            setChangeLocationPop(false)
        }, 500);
        setChangeLocationPopAnimate('changeAddressInn');
    }

    const [paymentPage, setPaymentPage] = useState(false);
    function showPayPage (){
        if(deliverTo != null)
        setPaymentPage(true)
        else {
            setAddAlert(true);
            setTimeout(function() {
                setAddAlert(false)
            }, 2000);
        }
    }

    const [couponCode, setCouponCode] = useState('');

    function enterCoupon(){
        const tokenString = sessionStorage.getItem('user_id');
        const productArray = []
        cartList.cartDetails.forEach(function (product) { 
            productArray.push(
                {
                    'product_id' : product.id,
                    'qty' : product.qty,
                }
            )
        })
        const data = {
            'products' : productArray,
            "coupon" : couponCode,
            "user_id" : tokenString,
        }
        const data2 = JSON.stringify(data)
        // console.log(data2)
        // console.log(data)

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
                'Content-Type': 'application/json',
            },            
            body: data2
          };
          
          fetch(`${window.url}/validCoupon`, requestOptions)
          .then((response) => response.json())
          .then(function(responce){
            console.log(responce)
                
            if(responce.error === 0){
                setFinalTotalPrice(responce.finalTotalprice)
            } 
             
        }) 
    }


    return(
        <>      

            {loadingScreen ? 
            <section className="loadingScreen">
                <span className="loader">
                <Oval
                ariaLabel="loading-indicator"
                height={100}
                width={100}
                strokeWidth={5}
                strokeWidthSecondary={2}
                color="#ffc659"
                secondaryColor="#f7f7f7"
                />
                </span>
            </section>:null
            } 

            <section className="productListHead">
                <Link className="backBttn"  onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
                <h4 className="wishListTxt">Order Summery</h4>
            </section> 
            
            <section className="deliverDetails">
                <h4>Deliver to : <button onClick={changeDeliveryLocation}>Change</button></h4>                                                            
                <span>
                    {deliverTo != null ?<h3>{deliverTo?.name} <small>{deliverTo?.phone}</small></h3>:null}
                </span>

                {deliverTo != null ?
                    <span>
                        <p>{deliverTo?.address_line_1} {deliverTo?.address_line_2} {deliverTo?.pin} <Link to={'/app/editDeliveryLocation/'+deliverTo?.id}><FontAwesomeIcon icon="fa-pencil" /></Link></p>
                    </span>:<Link className="deliveryPopTrg anchor"  to='/app/addlocation'>Add Delivery Address</Link>    
                }
                                    
            </section>

            {changeLocationPop ?
                <section className="changeAddressPopWrap">
                    <div className={changeLocationPopAnimate}>
                        {deliveryAddress.locations != null ? 
                            <div className="changeAddContent">
                                {Object.values(deliveryAddress?.locations).map((location, key) => { 
                                    return (                  
                                        <label key={key}>
                                            <input type="radio" name="radio" checked={location.id === deliverTo.id ? true : false} value={location} onChange={() => changeLocation(location)} />
                                            <p><b>{location?.name}</b><br /> {location?.address_line_1} {location?.address_line_2} {location?.pin}</p>                                            
                                        </label>
                                    );
                                })}
                                <Link className="deliveryPopTrg anchor"  to='/app/addlocation'>Add New Delivery Address</Link>
                            </div>:null
                        }
                    </div>
                    <div className="closepop" onClick={closePop}></div>
                </section>:null
            }

            { cartList?.cartDetails != null ?
                <div className="wishListTotalWrap checkout">
                    {Object.values(cartList?.cartDetails).map((CartBox, key) => {
                        return (
                            <div className="listBox osList" key={key}>
                                <picture>
                                    <Link to={'/app/productdetails/'+CartBox?.id}>
                                        <img src={CartBox.images} alt="" />
                                    </Link>
                                </picture>
                                <h4>{CartBox.title}<small>
                                    <b className={CartBox?.discount >0 ? "stripped" : "notStripped"}>Rs : <strong>{CartBox.price}</strong></b>    
                                    <b className={CartBox?.discount >0 ? "" : "hide"}>Rs : <strong>{CartBox.final_price}</strong></b>    
                                </small></h4>
                                <button className="DelectSlot" onClick={() => swipeTodelete(CartBox.cart_id)}><FontAwesomeIcon icon="fa-trash-can" /></button>
                                <QuantityPicker min={1} value={CartBox.qty} onChange={(qtyValue) => qtyPicker(qtyValue, CartBox.cart_id)} />
                            </div> 
                        );
                    })}
                </div>: null
            }

            <div className="couponCodeBox">
                <input type="text" placeholder="Enter Coupon Code" className="inpuCoupon" onChange={(e) => setCouponCode(e.target.value)} />
                <button onClick={enterCoupon}>Submit</button>
            </div>
            

            { shortAlert ?
            <span className="errAlert wishRemove">Removed</span>:null
            }

            { addAlert ?
            <span className="errAlert large">Add Delivery Address</span>:null
            }

            <div className="priceDetails">
                <h3>Price details</h3>
                <span className="detailsBlock">
                    <b>Price ({cartList.numberOfProduct} Items)</b>
                    <b>Rs: {finalTotalPrice}</b>
                </span>
                <span className="detailsBlock">
                    <b>Delivery Charge</b>
                    <b>Free Delivery</b>
                </span>
            </div>

            <div className="cartTotalDes">
                <h4>Total Price : <strong>{finalTotalPrice}</strong></h4> 

                <button onClick={showPayPage} className={cartList.total > 0 ? "cBttn show" : "cBttn"}>Checkout <FontAwesomeIcon icon="fa-angle-right" /></button>
            
            </div>

            {paymentPage ? <Payment deliverTo={deliverTo} finalTotalPrice={finalTotalPrice} couponCode={couponCode}  />:null}
            

            

        </>
    )
}
export default Checkoutproduct;