import { useParams, Link } from "react-router-dom";
import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useEffect, useState } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faPhone, faMapMarkerAlt, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Orderdetails(){
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState('');
    library.add( faUser, faPhone, faMapMarkerAlt, faArrowRight );

    useEffect(()=>{
        const formData = new FormData();
        formData.append('id', id);
        const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
        };      
        fetch(`${window.url}/admin/orderDetails`, requestOptions)
          .then((result)=>{
            result.json().then((resp)=>{
                setOrderDetails(resp)
            })
        });
    },[id]);


    return(
        <>
            <main className='adminPanelMainWrap'>
                <Aside></Aside>
                <Rightelements></Rightelements>
                <section className="maindbBody">
                    <Header></Header>
                    <div className="completeProductWrapper">
                        <div className="cpwHead">
                            <h2>Order Details</h2>
                        </div> 
                        
                        <div className="orderDetailsCompleteBlog">

                            { orderDetails?.orderDetails != null ?
                                <div className="detailsPart">
                                    <p><FontAwesomeIcon icon="fa-user" /> {orderDetails?.orderDetails?.name}</p>
                                    <p><FontAwesomeIcon icon="fa-phone" /> {orderDetails?.orderDetails?.phone}</p>
                                    <p><FontAwesomeIcon icon="fa-map-marker-alt" /> {orderDetails?.orderDetails?.address_line_1}, {orderDetails?.orderDetails?.address_line_2}, {orderDetails?.orderDetails?.pin}</p>
                                </div>:null
                            }

                            { orderDetails?.products != null ?
                                <div className="productList">
                                    {Object.values(orderDetails?.products).reverse().map((productBox, key) => {
                                        return (
                                        <div className='productBox' key={key}>
                                            <picture>
                                                <img src={productBox?.images} alt="" />
                                            </picture>
                                            <h4>{productBox?.title} ({productBox?.qty})
                                                <small>Rs: {productBox?.total_price}</small>
                                            </h4>
                                            <Link className="LiBttn"><FontAwesomeIcon icon="fa-arrow-right" /></Link>
                                        </div>
                                        );
                                    })}
                                </div>:null
                            }
                            { orderDetails?.orderDetails != null ?
                                <div className="priceSection">
                                    <p>Total Quantity : {orderDetails?.orderDetails?.total_qty}</p>
                                    <p>Total Price : {orderDetails?.orderDetails?.total_price}</p>
                                </div>:null
                            }

                        </div>

                    </div> 
                </section>
            </main>  
        </>
    )
}

export default Orderdetails;