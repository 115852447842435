import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Applogin() {
  library.add(faArrowLeft, faEye, faEyeSlash);
  const navigate = useNavigate();

  const [name,setName] = useState("")
  const [username,setUsername] = useState("")
  const [password,setPassword] = useState("")
  const [email,setEmail] = useState("")

  
  const [errName, setErrName] = useState("formFild")
  const [errUsername, setErrUsername] = useState("formFild")
  const [errEmail, setErrEmail] = useState("formFild")
  const [errPass, setErrPass] = useState("formFild")
  const [isRevealPwd, setIsRevealPwd] = useState(false)
  const [correctEmailAddress, setCorrectEmailAddress] = useState(false)
  const [exiestsUserName, setExiestsUserName] = useState(false)

  function register(){
    let item={name, username, password, email}

    const formData = new FormData();
    formData.append('name', item.name);
    formData.append('username', item.username);
    formData.append('email', item.email);
    formData.append('password', item.password);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
        body: formData
    };
    fetch(`${window.url}/user/registration`, requestOptions)
    .then((response) => response.json())
    .then(function(data){ 
        console.log(data)
        if(data.error === 0){
          setTimeout(function() {
            navigate('/app/login');
          }, 1000);  
        } 

        if(data.msg.includes("name required")){
          setErrName('formFild err')
        }else {
          setErrName('formFild success')
        }
        
        if(data.msg.includes("username required")){
          setErrUsername('formFild err')
        }else {
          setErrUsername('formFild success')
        }
        if(data.msg.includes("username already exists")){
          setErrUsername('formFild err')
          setExiestsUserName(true)
          setTimeout(function() {
            setExiestsUserName(false);
          }, 2000); 
        }

        if(data.msg.includes("email required")){
          setErrEmail('formFild err')
        }

        if(data.msg.includes("invalid_email_format")){
          setErrEmail('formFild err')          
          setCorrectEmailAddress(true)
          setTimeout(function() {
            setCorrectEmailAddress(false);
          }, 2000); 
        }

        // else if(data.msg.includes("invalid_email_format")){
        //   setErrEmail('formFild err')
        // }else {
        //   setErrEmail('formFild success')
        // }

        if(data.msg.includes("password required")){
          setErrPass('formFild err')
        }else {
          setErrPass('formFild success')
        }

    })
  }

  function pressKey(){
    if(name !== '') { 
      setErrName('formFild success')
    }
    if(username !== '') { 
      setErrUsername('formFild success')
    }
    if(email !== '') { 
      setErrEmail('formFild success')
    }
    if(password !== '') { 
      setErrPass('formFild success')
    }

  }


  return (
    <>
        <section className="LoginPage">
          <div className="logMain register">
              <h1>Register</h1>
              <div className="formMain">
                <input type="text" value={name} onKeyUp={pressKey} onChange={(e)=>setName(e.target.value)} placeholder="Name" className={errName} />
                <input type="text" value={username} onKeyUp={pressKey} onChange={(e)=>setUsername(e.target.value)} placeholder="User Name" className={errUsername} />
                <input type="email" value={email} onKeyUp={pressKey} onChange={(e)=>setEmail(e.target.value)} placeholder="Email" className={errEmail} />
                <input type={isRevealPwd ? "text" : "password"} value={password} onKeyUp={pressKey} onChange={(e)=>setPassword(e.target.value)} placeholder="Password" className={errPass} />
                <FontAwesomeIcon icon={isRevealPwd ? 'fa-eye-slash' : 'fa-eye'} className="showHidePass onReg" onClick={() => setIsRevealPwd(prevState => !prevState)} />
                <button onClick={register}>Register</button>
              </div>
              <p>No Account ? <Link to='/app/login'>Login Now</Link></p>
          </div>
          {/* <Link className="backBttn" to='/'><FontAwesomeIcon icon="fa-arrow-left" /> Home</Link> */}
          {correctEmailAddress ?<span className="errAlert large fromCenter">Enter a valid email</span>:null}
          {exiestsUserName?<span className="errAlert large fromCenter">Username already exists</span>:null}
        </section>
    </>
  );
}

export default Applogin;
