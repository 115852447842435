import { NavLink } from "react-router-dom";

import homeicon from '../images/homeIcon.svg'
import productIcon from '../images/productsIcon.svg'
import categoriesIcon from '../images/categoriesIcon.svg'
import orderIcon from '../images/orderIcon.svg'
import usersIcon from '../images/usersIcon.svg'
import settingsIcon from '../images/settingsIcon.svg'
import logoutIcon from '../images/logoutIcon.svg'
function Aside() {
  function logout(){
    sessionStorage.clear()
  }
  return (
    <>
        <aside>
            <div className="sidebarLinks">
                <span><NavLink to="/admin/dashboard"><img src={homeicon} alt="" /> dashboard</NavLink></span>
                <span><NavLink to="/admin/products"><img src={productIcon} alt="" /> products</NavLink></span>
                <span><NavLink to="/admin/categories"><img src={categoriesIcon} alt="" /> categories</NavLink></span>
                <span><NavLink to="/admin/subcategories"><img src={orderIcon} alt="" /> subcategories</NavLink></span>
                <span><NavLink to="/admin/customers"><img src={usersIcon} alt="" /> customers</NavLink></span>
                <span><NavLink to="/admin/storylist"><img src={orderIcon} alt="" /> story list</NavLink></span>
                <span><NavLink to="/admin/discounts"><img src={orderIcon} alt="" /> Discounts</NavLink></span>
                <span><NavLink to="/admin/coupon"><img src={orderIcon} alt="" /> Coupons</NavLink></span>
                <span><NavLink to="/admin/orders"><img src={orderIcon} alt="" /> orders</NavLink></span>
            </div>
            <div className="sidebarLinks bottomLinks">
                <span><NavLink to="/admin/settings"><img src={settingsIcon} alt="" /> settings</NavLink></span>
                <span><NavLink to="/admin/login" onClick={logout}><img src={logoutIcon} alt="" /> logout</NavLink></span>
            </div>
        </aside>          
    </>
  );
}

export default Aside;
