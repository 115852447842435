import Footer from "./components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faBell, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function EditDeliveryLocation() {
    library.add( faArrowLeft, faBell, faPencil );
    const navigate = useNavigate();
    const { id } = useParams();
    const [deliveryDetails, setDeliveryDetails] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [addressLineOne, setAddressLineOne] = useState('');
    const [addressLineTwo, setAddressLineTwo] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [shortAlert, setShortAlert] = useState(false)

    useEffect(()=>{        
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
        };
        Promise.all([
            fetch(`${window.url}/fetchDeliveryAddressById/${id}`, requestOptions)
        ]).then(([deliveryDetails])=>
            Promise.all([deliveryDetails.json()])
        ).then((data) => {
            setDeliveryDetails(data[0]);
            setAddressLineOne(data[0]?.address?.address_line_1)
            setAddressLineTwo(data[0]?.address?.address_line_2)
            setName(data[0]?.address?.name)
            setPhone(data[0]?.address?.phone)
            setPinCode(data[0]?.address?.pin)
        })      
    },[]);


    function editLocation(){
      const formData = new FormData();
      formData.append('id', id);
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('address_line_1', addressLineOne);
      formData.append('address_line_2', addressLineTwo);
      formData.append('pin', pinCode);

      const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/editDeliveryAddress`, requestOptions)
      .then((response) => response.json())
      .then(function(data){ 
        console.log(data)    
        if(data.error === 0){
            setShortAlert(true);
            setTimeout(function() {
              setShortAlert(false);
              navigate(-1)
            }, 800);
        }
        
      })
    }
    
    return(
        <>
            <section className="productListHead">
                <Link className="backBttn"  onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
                <span className="rightWigt">
                    <button><FontAwesomeIcon icon="fa-bell" /></button>
                </span>
                <h4 className="wishListTxt">Edit Address</h4>
            </section> 

            <div className="getLocationPopWrp editDAddress">
                <section className="deliveryWrap">
                    { deliveryDetails?.address != null ?    
                        <div className="locationWrapMain">
                            <h4>Edit Delivery Address </h4>
                            <input type="text" placeholder="Name" value={name || ''} onChange={(e)=>setName(e.target.value)} />
                            <input type="text" placeholder="Phone" value={phone || ''} onChange={(e)=>setPhone(e.target.value)} />
                            <input type="text" placeholder="Address Line 1" value={addressLineOne || ''} onChange={(e)=>setAddressLineOne(e.target.value)} />
                            <input type="text" placeholder="Address Line 2" value={addressLineTwo || ''} onChange={(e)=>setAddressLineTwo(e.target.value)}/>
                            <input type="text" placeholder="Pincode" value={pinCode || ''} onChange={(e)=>setPinCode(e.target.value)} />
                            <button className="enterLBttn" onClick={editLocation}>Submit</button>
                        </div>:null
                    }
                </section>
            </div>

            {shortAlert ?
            <span className="errAlert wishRemove large">Location Edited</span>:null
            }

            <Footer></Footer>
        </>
    )
}
export default EditDeliveryLocation;