import Footer from "./components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faBell, faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Addlocation() {
    library.add( faArrowLeft, faBell, faPencil, faTrashCan );
    const navigate = useNavigate();

    const [deliveryDetails, setDeliveryDetails] = useState('')

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [addressLineOne, setAddressLineOne] = useState('');
    const [addressLineTwo, setAddressLineTwo] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [addDeliveryAddress, setAddDeliveryAddress] = useState(false);
    const [shortAlert, setShortAlert] = useState(false);
    const [addAddressAnimate, setAddaddressAnimate] = useState('locationWrapMain')

    useEffect(()=>{
        addressAdded()        
    },[]);

    function addressAdded() {
        const tokenString = sessionStorage.getItem('user_id');
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
        };
        Promise.all([
            fetch(`${window.url}/fetchDeliveryAddress/${tokenString}`, requestOptions)
        ]).then(([deliveryDetails])=>
            Promise.all([deliveryDetails.json()])
        ).then((data) => {
            setDeliveryDetails(data[0]);
        })
    }


    function addLocation(){
      const id = sessionStorage.getItem('user_id');

      const formData = new FormData();
      formData.append('user_id', id);
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('address_line_1', addressLineOne);
      formData.append('address_line_2', addressLineTwo);
      formData.append('pin', pinCode);

      const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/deliveryAddress`, requestOptions)
      .then((response) => response.json())
      .then(function(data){ 
        console.log(data)    
        if(data.error === 0){
          addressAdded()
          closeDpop ()
          setShortAlert(true);
          setTimeout(function() {
            setShortAlert(false);
          }, 2000);
        }      
        
      })
    }

    function addLocationPop () {
        setAddDeliveryAddress(true)
        setTimeout(function() {
            setAddaddressAnimate('locationWrapMain animate');
        }, 0);
    }
    function closeDpop () {
        setTimeout(function() {
            setAddDeliveryAddress(false)
        }, 500);
        setAddaddressAnimate('locationWrapMain');
    }

    function deleteLocation(id){
        const formData = new FormData();
            formData.append('id', id);

            const requestOptions = {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${window.token}`,
              },
              body: formData
          };
          fetch(`${window.url}/deleteDeliveryAddress`, requestOptions)
          .then((response) => response.json())
          .then(function(data){   
            console.warn(data)
            if(data.error === 0){
                addressAdded()
                // setShortAlert(true);
                // setTimeout(function() {
                //   setShortAlert(false);
                // }, 3000);
            }  
        }) 
    }
    
    return(
        <>
            <section className="productListHead">
                <Link className="backBttn"  onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
                <span className="rightWigt">
                    <button><FontAwesomeIcon icon="fa-bell" /></button>
                </span>
                <h4 className="wishListTxt">Delivery Address</h4>
            </section> 

            <div className="getLocationPopWrp">

                {addDeliveryAddress ?
                    <section className="deliveryWrap addLocationAnimation">
                        <div className={addAddressAnimate}>
                            <h4>Add Delivery Address </h4>
                            <input type="text" placeholder="Name" onChange={(e)=>setName(e.target.value)} />
                            <input type="text" placeholder="Phone No" onChange={(e)=>setPhone(e.target.value)} />
                            <input type="text" placeholder="Address Line 1" onChange={(e)=>setAddressLineOne(e.target.value)} />
                            <input type="text" placeholder="Address Line 2" onChange={(e)=>setAddressLineTwo(e.target.value)}/>
                            <input type="text" placeholder="Pincode" onChange={(e)=>setPinCode(e.target.value)} />
                            <button className="enterLBttn" onClick={addLocation}>Submit</button>
                        </div>
                        <div className="closeDPop" onClick={closeDpop}></div>
                    </section>:null
                }

                { deliveryDetails?.locations != null ?                
                    <section className="locationListing">
                    {Object.values(deliveryDetails?.locations).map((location, key) => {
                        return (
                        <div className="locationBox" key={key}>   
                            <p>{location?.name}</p>
                            <p>{location?.phone}</p>
                            <p>{location?.address_line_1} {location?.address_line_2}</p>
                            <p>PinCode : {location?.pin}</p>
                            <button onClick={() => deleteLocation(location?.id)}><FontAwesomeIcon icon="fa-trash-can" /></button>
                            <Link to={'/app/editDeliveryLocation/'+location?.id}><FontAwesomeIcon icon="fa-pencil" /></Link>
                        </div>
                        );
                    })}
                    </section>: null
                }

                <div className="deliveryPopTrg" onClick={addLocationPop}>Add Delivery Address</div>




            </div>

            {shortAlert ?
                <span className="errAlert fromCenter wishRemove large">Add New Address</span>:null
            }

            <Footer></Footer>
        </>
    )
}
export default Addlocation;