import { useParams } from "react-router-dom";
import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import ReactSlider from "react-slider";
import { Oval } from 'react-loader-spinner';
import { useState, useEffect } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faHeart, faSearch, faPlus, faFilter, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Productlist() {
  // const navigate = useNavigate();
  library.add(faArrowLeft, faHeart, faSearch, faPlus, faFilter, faShoppingCart);
    const { id } = useParams();
    const [filterPop, setfilterPop] = useState(false);
    const [filterPopAnimation, setfilterPopAnimation] = useState('filtermainContain');
    const [productListing, setProductListing] = useState('');
    const [shortAlert, setShortAlert] = useState(false);
    const [subCatList, setSubCatList] = useState('');
    const [valuePrice, setValuePrice] = useState([]);
    const [wishListing, setWishListing] = useState('');
    const [showWishCount, setShowWishCount] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);

    const navigate = useNavigate();

    function addedWishList(){
      const tokenString = sessionStorage.getItem('user_id');

      const formData = new FormData();
        formData.append('cat_id', id);
        
        if(tokenString != null){
          formData.append('user_id', sessionStorage.getItem('user_id'));
        }

        const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
        };
        const wishOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };
        Promise.all([
            fetch(`${window.url}/fetchProductByCategroy`, requestOptions),
            fetch(`${window.url}/wishListOfUser/${tokenString}`, wishOptions)
        ]).then(([productListing, wishListing])=>
            Promise.all([productListing.json(), wishListing.json()])
        ).then((data) => {
          setProductListing(data[0]);
          setWishListing(data[1]);
        })

    }

    const[cartDetails, setCartDetails] = useState('');

    useEffect(()=>{
      const tokenString = sessionStorage.getItem('user_id');
      addedWishList()
      const formData = new FormData();
      formData.append('id', id);
      formData.append('user_id', tokenString);

        const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
        };
        Promise.all([
            fetch(`${window.url}/fetchSubCategoryByCategroy`, requestOptions),
            fetch(`${window.url}/cartDetails`, requestOptions)
        ]).then(([subCatList, cartDetails])=>
            Promise.all([subCatList.json(), cartDetails.json()])
        ).then((data) => {
          setSubCatList(data[0]);
          setCartDetails(data[1])
        })
        if(tokenString != null){
          setShowWishCount(true)
        }else{setShowWishCount(false)}
        
        setLoadingScreen(true);
        setTimeout(function() {
          setLoadingScreen(false);
        }, 500);
    }, []); 

    function addToWishlist(id){
        const formData = new FormData();
        formData.append('product_id', id);
        formData.append('user_id', sessionStorage.getItem('user_id'));
        
        const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
        };
        
        fetch(`${window.url}/addToWishList`, requestOptions)
        .then((response) => response.json())
        .then(function(data){
          // console.warn(data)
          if(data.msg === 'success'){
            addedWishList()
          } 
          if(data.error === 1){
            setShortAlert(true);
            setTimeout(function() {
              setShortAlert(false);
            }, 3000);
          } 
        })      
    }

    function showFilter(){
      setfilterPop(true);
      setTimeout(function() {
        setfilterPopAnimation('filtermainContain open')
      }, 0); 
    }

    function closeFilter(){
      setTimeout(function() {
        setfilterPop(false);
      }, 310);      
      setTimeout(function() {
        setfilterPopAnimation('filtermainContain')
      }, 0);
    }

    var subCatArray = []    
    function getSelectedSubCatIds(value, checked){
      if(checked){
        subCatArray.push(value)
      }else{
        const index = subCatArray.indexOf(value);
        subCatArray.splice(index, 1);
      }
    }

    function filterProducts(){  
      // console.log(subCatArray)    
      const formData = new FormData();
      formData.append('sub_cat_ids', subCatArray);
      formData.append('cat_id', id);

      if(valuePrice[1] === undefined){
        formData.append('maxPrice', "");
      }else{formData.append('maxPrice', valuePrice[1]);}        
      if(valuePrice[0] === undefined){
        formData.append('minPrice', "");
      }else{formData.append('minPrice', valuePrice[0]);}
  
      const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/filterProductOnCategory`, requestOptions)
      .then((response) => response.json())
      .then(function(data){
        // console.warn(data)        
        setProductListing(data);
      })
      
      setTimeout(function() {
        setfilterPop(false);
      }, 310);      
      setTimeout(function() {
        setfilterPopAnimation('filtermainContain')
      }, 0);  
    }

    function wishList(){
      const tokenString = sessionStorage.getItem('user_id');
      if(tokenString != null){
        navigate('/app/wishlist');
      }else {
        setShortAlert(true);
        setTimeout(function() {
          setShortAlert(false);
        }, 3000);
      }
    }

    function addCart(){
      const tokenString = sessionStorage.getItem('user_id');
      if(tokenString != null){
        navigate('/app/cart');
      }else {
        setShortAlert(true);
        setTimeout(function() {
          setShortAlert(false);
        }, 3000);
      }  
    }

    function storeLastVisit(id){
      const tokenString = sessionStorage.getItem('user_id');
      const formData = new FormData();
      formData.append('user_id', tokenString);    
      formData.append('product_id', id);    
      const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/addRecentViews`, requestOptions)
      .then((response) => response.json())
      .then(function(data){
          console.warn(data)
      })
    }

    
  
    return (
      <>     

      {loadingScreen ? 
        <section className="loadingScreen">
          <span className="loader">
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            strokeWidthSecondary={2}
            color="#ffc659"
            secondaryColor="#f7f7f7"
          />
          </span>
        </section>:null
      }
      
      <section className="productListHead">
        <Link className="backBttn"  onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
        <span className="rightWigt">
          <button onClick={addCart}><FontAwesomeIcon icon="fa-shopping-cart" />
            {showWishCount ?
              <b>{cartDetails.numberOfProduct}</b>: null
            }
          </button>
          <button onClick={wishList}><FontAwesomeIcon icon="fa-heart" />
            {showWishCount ?
              <b>{wishListing.numberOfWishList}</b>: null
            }
          </button>
          {/* <button><FontAwesomeIcon icon="fa-search" /></button> */}
          <button onClick={showFilter}><FontAwesomeIcon icon="fa-filter" /></button>
        </span>
      </section>
      <h4 className="productListHead">{productListing?.category}</h4>


        { productListing?.products != null ?
        <section className="productListWrap">
          {Object.values(productListing?.products).reverse().map((product, key) => {
            return (
              <div className="productBox" key={key}> 
                <span className={product?.discount >0 ? "discountInfo show" : "discountInfo hide"}>{product?.discount}% Off</span>  
                <button className={product?.wishlisht === 1 ? "addWishLIst added" : "addWishLIst"} onClick={() => addToWishlist(product?.id)}><FontAwesomeIcon icon='fa-heart'/></button>
                <picture>
                  <Link to={'/app/productdetails/'+product?.id}>
                  <img src={product?.images} alt="" />
                  </Link>
                </picture> 
                <h3>
                  <small>{product?.title}</small>
                  <b className={product?.discount >0 ? "stripped" : "notStripped"}>₹ {product?.price}</b>
                  <b className={product?.discount >0 ? "" : "hide"}>₹ {product?.final_price}</b>
                </h3>  
                <Link className="productDetailsbttn" onClick={() => storeLastVisit(product?.id)} to={'/app/productdetails/'+product?.id}><FontAwesomeIcon icon='fa-plus'/></Link>       
              </div>
            );
          })}
        </section>: null
        }

        { productListing?.products == null ?
          <h1 className="noProductFind">No products found</h1>:null
        }

        {shortAlert ?
          <span className="errAlert">Login to continue</span>:null
        }

        { filterPop ? 
          <section className="filterPopupWrap">
            <div className={filterPopAnimation}>

            <div className="priceRangeWrap">
              <h4>Set Price</h4>
              <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  defaultValue={[productListing.minPrice, productListing.maxPrice]}
                  ariaLabel={['Lower thumb', 'Upper thumb']}
                  ariaValuetext={state => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                  pearling
                  minDistance={10}
                  onChange={setValuePrice}
                  min={productListing.minPrice}
                  max={productListing.maxPrice}
                  value={valuePrice}
              />
            </div>
              
              <div className="selectSubcategories">
                <h4>Select Subcategory</h4>
                  <div className="subCatList">
                    {Object.values(subCatList.subCategories).map((subCat, key) => {
                      return ( 
                        <label className="subCatBox"  key={key}><input onChange={(event)=>getSelectedSubCatIds(event.target.value, event.target.checked)} type="checkbox" id={subCat.id} value={subCat.id} /><p>{subCat.title}</p><b></b></label>
                      );
                    })}
                  </div>
              </div>

              <button className="filterBttn" onClick={filterProducts}>Filter Products</button>
              
            </div>
            <div className="closeFilterPop" onClick={closeFilter}></div>
          </section>:null
        }

        
        
        <Footer></Footer>
      </>
    );
  }
  
  export default Productlist;
  