import Customers from './Customers';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
function Rightelements() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(()=>{
    const tokenString = sessionStorage.getItem('username');
    if(tokenString != null){
      const formData = new FormData();
        formData.append('username', tokenString);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/admin/details`, requestOptions)
      .then((response) => response.json())
      .then(function(data){ 
        if(data.msg === 'username_not_find'){              
        }else {
          setCurrentUser(data)
          // console.warn(data)
        }
          
      }) 
    }
    else {
    }
  },[]);

    return (
      <>  
        <section className="rightSideContents">
            <div className="adminProfile">
                <picture></picture>
                <h4>{  currentUser != null ? currentUser.adminDetails.name : 'Anonymous'} 
                    <small>{  currentUser != null ? currentUser.adminDetails.email : 'Have a good day'} </small>
                </h4>
                <Link className="profileBttn">Edit Profile</Link>
            </div>
            <Customers></Customers>
        </section>                     
      </>
    );
  }
  
  export default Rightelements;