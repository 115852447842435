import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
function Editcoupon() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [Categories, setCategories] = useState('');
    const [couponDetails, setCouponDetails] = useState('');

    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');

    useEffect(()=>{ 
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };            
        Promise.all([
            fetch(`${window.url}/admin/fetchCategory`, requestOptions),
            fetch(`${window.url}/admin/couponDetails/${id}`, requestOptions),
        ]).then(([categoryId, couponDetails])=>
            Promise.all([categoryId.json(), couponDetails.json()])
        ).then((data) => {
          setCategories(data[0]);
          setCouponDetails(data[1]);
          setStartDate(data[1]?.couponDetails?.start_date);
          setEndDate(data[1]?.couponDetails?.end_date);
          setCouponCode(data[1]?.couponDetails?.coupon);
          setSelectedCategoryId(data[1]?.couponDetails?.cat_id);
          setDiscountAmount(data[1]?.couponDetails?.discount);
        })
    },[]);

    function editCouponList(){

        const formData = new FormData();    
        formData.append('cat_id', selectedCategoryId);
        formData.append('start_date', startDate);
        formData.append('end_date', endDate);
        formData.append('coupon', couponCode);
        formData.append('discount', discountAmount);
        formData.append('id', id)
    
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        fetch(`${window.url}/admin/editCoupon`, requestOptions)
        .then((response) => response.json())
        .then(function(data){ 
            console.log(data)
          if(data.error === 0){
            setTimeout(function() {
                navigate(-1);
            }, 500); 
          } 
        })
      }

  return (
    <>
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            
            <div className="completeProductWrapper">
              <div className="cpwHead">
                <h2>Edit Coupon</h2>
              </div>

              {couponDetails?.couponDetails != null ?
                <div className="addProductWrap">
                    <label className='textLabel'>Select Category</label> 
                    <select className="usrInpt" value={selectedCategoryId} onChange={(e)=>setSelectedCategoryId(e.target.value)}>
                        <option>Select Category</option>    
                        {Object.values(Categories).map((category, key) => {
                            return (
                            <option value={category.id} key={key}>{category.title}</option>
                            );
                        })}
                    </select>   
                    <label className='textLabel'>Start Date</label> 
                    <input type="date" className='usrInpt' value={startDate} onChange={(e)=>setStartDate(e.target.value)}/>     
                    <label className='textLabel'>End Date</label> 
                    <input type="date" className='usrInpt' value={endDate} onChange={(e)=>setEndDate(e.target.value)}/>   
                    <label className='textLabel'>Coupon Code</label> 
                    <input type="text" className='usrInpt' value={couponCode} onChange={(e)=>setCouponCode(e.target.value)}/> 
                    <label className='textLabel'>Discount Amount</label> 
                    <input type="number" value={discountAmount} className='usrInpt' onChange={(e)=>setDiscountAmount(e.target.value)}/> 
                    <button onClick={editCouponList}>Edit Coupon</button>
                </div>:null
              }




            </div>

          </section>          
        </main>            
    </>
  );
}

export default Editcoupon;
