import Footer from "./components/Footer";
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import cameraIcon from '../images/cameraIcon.svg'
import galleryIcon from '../images/galleryIcon.svg'
import { Oval } from 'react-loader-spinner';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faBell, faArrowRight, faPencil, faMapMarkedAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import GoogleMapReact from 'google-map-react';

function Profile() {
  library.add( faArrowLeft, faBell, faArrowRight, faPencil, faMapMarkedAlt, faTimes );
    const navigate = useNavigate();
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [chooseImage, setChooseImage] = useState(false);
    const [chooseImageAnimate, setChooseImageAnimate] = useState('choosaeImagecontain');
    const [shortAlert, setShortAlert] = useState(false);
    const [editedAlert, setEditedAlert] = useState(false);
    const [phoneAlert, setPhoneAlert] = useState(false);
    const [userNameAlert, setUserNameAlert] = useState(false);
    const [emailAlert, setEmailAlert] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState('');
    const [profileDetails, setProfileDetails] = useState('');
    const [editProfilePop, setEditProfilePop] = useState(false);
    const [editProfilePopAnimate, setEditProfilePopAnimate] = useState('editProfileFrm');

    const [name, setName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    function edited(){ 
      const tokenString = sessionStorage.getItem('user_id');        
      const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
    };
    Promise.all([
        fetch(`${window.url}/profileDetails/${tokenString}`, requestOptions)
    ]).then(([profileDetails])=>
        Promise.all([profileDetails.json()])
    ).then((data) => {
      setProfileDetails(data[0]);
      setProfilePhoto(data[0]?.profileDetails?.user_image);
      setName(data[0]?.profileDetails?.name);
      setUserName(data[0]?.profileDetails?.username);
      setEmail(data[0]?.profileDetails?.email);
      setPhone(data[0]?.profileDetails?.phone);
      // setAddressLineOne(data[0]?.profileDetails?.address_line_1);
      // setAddressLineTwo(data[0]?.profileDetails?.address_line_2);
      // setPinCode(data[0]?.profileDetails?.pin);
    })
    }

    useEffect(()=>{
      const tokenString = sessionStorage.getItem('user_id');
      if(tokenString === null){
        navigate('/app/login');
      }
      setLoadingScreen(true);
      setTimeout(function() {
        setLoadingScreen(false);
      }, 500);
      
      edited()

    },[navigate]);
    

    function chooseImgcarpureBttn(){
      setChooseImage(true);
      setTimeout(function() {
        setChooseImageAnimate('choosaeImagecontain open');
      }, 0);      
    }
    function removechoosePop (){
      setTimeout(function() {
        setChooseImage(false);
      }, 400);        
      setChooseImageAnimate('choosaeImagecontain');
    }

    function handelImage(image){

      const tokenString = sessionStorage.getItem('user_id');
      setProfilePhoto(URL.createObjectURL(image));

      const formData = new FormData();
      formData.append('user_id', tokenString);
      formData.append('user_image', image);

      const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/profileImage`, requestOptions)
      .then((response) => response.json())
      .then(function(data){ 
        // console.warn(data)  
        if(data.error === 0){
          setShortAlert(true);
          setTimeout(function() {
            setShortAlert(false);
          }, 2500);          
          setTimeout(function() {
            setChooseImage(false);
          }, 400);        
          setChooseImageAnimate('choosaeImagecontain');
        }         
      })

    }

    function logOut(){
      sessionStorage.clear();
      navigate('/app/login');
    }

    function editProfilePopTrg(){
      setEditProfilePop(true);
      setTimeout(function() {
        setEditProfilePopAnimate('editProfileFrm open');
      }, 0);   
    }
    function closeEditPop(){
      setTimeout(function() {
        setEditProfilePop(false);
      }, 400);  
      setEditProfilePopAnimate('editProfileFrm');
    }

    function editProfile(){
      const tokenString = sessionStorage.getItem('user_id');

      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('username', userName);
      formData.append('phone', phone);
      formData.append('id', tokenString);

      const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/editProfile`, requestOptions)
      .then((response) => response.json())
      .then(function(data){ 
          // console.log(data);
          edited();
          if(data.error === 0){
            setEditedAlert(true);
            setTimeout(function() {
              setEditedAlert(false);
            }, 2500);  
            closeEditPop()
          }
          if(data.msg.phone === 'phone already exists'){
            setPhoneAlert(true);
            setTimeout(function() {
              setPhoneAlert(false);
            }, 2500);  
          }
          if(data.msg.username === 'username already exists'){
            setUserNameAlert(true);
            setTimeout(function() {
              setUserNameAlert(false);
            }, 2500);  
          }
          if(data.msg.email === 'email already exists'){
            setEmailAlert(true);
            setTimeout(function() {
              setEmailAlert(false);
            }, 2500);  
          }
      })
    }



  return (
    <>
      { loadingScreen ? 
        <section className="loadingScreen">
          <span className="loader">
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            strokeWidthSecondary={2}
            color="#ffc659"
            secondaryColor="#f7f7f7"
          />
          </span>
        </section>:null
      }

      
      { chooseImage ? 
        <div className="chooseimagePopWrap">
          <div className={chooseImageAnimate}>
            <label><img src={cameraIcon} alt="" /><input type="file" accept="image/*" capture onChange={(e)=>handelImage(e.target.files[0])} /></label>
            <label><img src={galleryIcon} alt="" /><input type="file" accept="image/*" onChange={(e)=>handelImage(e.target.files[0])}  /></label>
          </div>
          <div className="closeimgPop" onClick={removechoosePop}></div>
        </div>:null
      }
      {editProfilePop ?
        <div className="editProfileWrap">
          <div className={editProfilePopAnimate}>
            <h4>Edit Profile <FontAwesomeIcon icon="fa-times" onClick={closeEditPop} /></h4>
            <div className="formWrap">
              <input type="text" className="formFild" value={name || ''} onChange={(e)=>setName(e.target.value)} placeholder="Name" />
              <input type="text" className="formFild" value={userName || ''} onChange={(e)=>setUserName(e.target.value)} placeholder="User Name" />
              <input type="email" className="formFild" value={email || ''} onChange={(e)=>setEmail(e.target.value)} placeholder="Email Id" />
              <input type="number" className="formFild" value={phone || ''} onChange={(e)=>setPhone(e.target.value)} placeholder="Phone No" />
              <button className="submitBttn" onClick={editProfile}>Submit</button>
            </div>
          </div>
          <div className="closeProfileOverlay" onClick={closeEditPop}></div>
        </div>:null
      }

        <section className="productListHead profileHead">
            <Link className="backBttn" onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
            <span className="rightWigt">
                <button><FontAwesomeIcon icon="fa-bell" /></button>
            </span>
            <h4 className="wishListTxt">Profile</h4>
        </section> 

        { profileDetails?.profileDetails != null ?
        <section className="profilePageWrap">
          <div className="profilePageHead">
            <picture>
              <img src={profilePhoto} alt="" />
              <button onClick={chooseImgcarpureBttn}><FontAwesomeIcon icon="fa-pencil" /></button>
            </picture>
            <h4>{profileDetails?.profileDetails.name}
              <small>{profileDetails?.profileDetails.email}</small>
            </h4>
          </div>
          <div className="profilePageContents">
            <Link className="ppBlock" to="/app/cart">Cart List <FontAwesomeIcon icon="fa-arrow-right" /></Link>
            <Link className="ppBlock" to="/app/wishlist">Wish List <FontAwesomeIcon icon="fa-arrow-right" /></Link>
            <Link className="ppBlock" to="/app/orderlist">Order List <FontAwesomeIcon icon="fa-arrow-right" /></Link>
            <Link className="ppBlock" to="/app/addlocation">Delivery Address <FontAwesomeIcon icon="fa-arrow-right" /></Link>
            <div className="pPagebttns">
              <button className="editProfile" onClick={editProfilePopTrg}>Edit Profile</button>
              <button className="logOut" onClick={logOut}>Logout</button>
            </div>
          </div>
        </section> :null
        } 
        {shortAlert ?
          <span className="errAlert wishRemove fromCenter">Photo updated</span>:null
        }
        {
          editedAlert? <span className="errAlert wishRemove fromCenter">Profile Edited</span>:null
        }
        {phoneAlert ?
          <span className="errAlert large">phone already exists</span>:null
        }
        {userNameAlert ?
          <span className="errAlert large">username already exists</span>:null
        }
        {emailAlert ?
          <span className="errAlert large">email already exists</span>:null
        }


        <Footer></Footer>
    </>
  );
}

export default Profile;
