

function Customers() {


  return (
    <>
        <section className="customersListing">
            <h4>Customers</h4>
            <div className="mainListing">
                <div className="customerListBox">
                    <picture></picture>
                    <p>Soumyadeep Sadhu</p>
                </div>
            </div>
        </section>          
    </>
  );
}

export default Customers;
