import Footer from "./components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Seeallorders() {
    library.add( faArrowLeft, faArrowRight );
    const navigate = useNavigate();
    const { id } = useParams();

    const[completeOrderList, setCompleteOrderList] = useState('');

    useEffect(()=>{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
          };
          Promise.all([
              fetch(`${window.url}/seeAllProductInOrder/${id}`, requestOptions)
          ]).then(([completeOrderList])=>
              Promise.all([completeOrderList.json()])
          ).then((data) => {
            setCompleteOrderList(data[0]);
          })
    },[]); 


    return(
        <>
            <section className="productListHead">
                <Link className="backBttn"  onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
                <h4 className="wishListTxt">All Orderes</h4>
            </section> 

            { completeOrderList?.allProductsInOrder != null ?
                <section className="wishListTotalWrap">
                {Object.values(completeOrderList?.allProductsInOrder).map((orderBox, key) => {
                    return (
                    <div className="listBox" key={key}>   
                        <picture>
                            <Link to={'/app/productdetails/'+orderBox?.id}>
                            <img src={orderBox?.images} alt="" />
                            </Link>
                        </picture>
                        <h4>{orderBox?.title} ({orderBox?.qty})
                            <small>Rs: {orderBox?.price}</small>
                        </h4>
                            <Link to={'/app/productdetails/'+orderBox?.id} className="goToProduct"><FontAwesomeIcon icon="fa-arrow-right" /></Link>
                    </div>
                    );
                })}
                </section>: null
            }

            <Footer></Footer>
        </>
    )
}
export default Seeallorders;