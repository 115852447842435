import { Link} from "react-router-dom";
import { useState, useEffect } from "react";
function Offerslide() {

    const [discounts, setDiscounts] = useState('');
    useEffect(()=>{
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };      
        fetch(`${window.url}/offerList`, requestOptions)
          .then((result)=>{
            result.json().then((resp)=>{
                setDiscounts(resp);
            })
        });
    }, []); 

    function storeLastVisit(id){
        const tokenString = sessionStorage.getItem('user_id');
        const formData = new FormData();
        formData.append('user_id', tokenString);    
        formData.append('product_id', id);    
        const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        fetch(`${window.url}/addRecentViews`, requestOptions)
        .then((response) => response.json())
        .then(function(data){
            console.warn(data)
        })
      }


    return(
        <> 

                    { discounts?.offerList != null ?
                        <div className="bannerAddsection">                   

                            {Object.values(discounts?.offerList).map((discount, key) => {
                                return (
                                <Link  onClick={() => storeLastVisit(discount?.id)} to={'/app/productdetails/'+discount?.id} className='slideBox' key={key}>
                                    <img src={discount?.discount_image} alt="" />
                                </Link>
                                );
                            })}
                        </div> :null
                    }
        </>
    )
}
export default Offerslide;