import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
function Editstory() {
    const { id } = useParams();
    const [storyDetails, setStoryDetails] = useState('');
    const [products,setProductsList]=useState([]);



    const [title, setTitle] = useState('');
    const [productId, setProductId] = useState([]);
    const [video, setVideo] = useState([]);
    const [thumbImage, setThumbImage] = useState([]);
    
    useEffect(()=>{
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };
        Promise.all([
            fetch(`${window.url}/storyDetails/${id}`, requestOptions),
            fetch(`${window.url}/admin/fetchProducts`, requestOptions)
        ]).then(([storyDetails, products])=>
            Promise.all([storyDetails.json(), products.json()])
        ).then((data) => {
            setStoryDetails(data[0]);
            setTitle(data[0].storyDetails.title);
            setVideo(data[0].storyDetails.video);
            setProductId(data[0].storyDetails.product_id)
            setThumbImage(data[0].storyDetails.thumb_image)
            setProductsList(data[1])
        })

    }, [id]); 

    function editStory(){
        let item={title, productId, video, thumbImage}
        const formData = new FormData();
        formData.append('id', id);
        formData.append('title', item.title);
        formData.append('product_id', item.productId);
        formData.append('video', item.video);
        formData.append('thumb_image', item.thumbImage);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        // setImage(URL.createObjectURL(selectedImage));
        fetch(`${window.url}/admin/editStories`, requestOptions)
        .then((response) => response.json())
        .then(function(data){ 
            console.log(data)            
        })
    }

  return (
    <>
        <main className='adminPanelMainWrap'>
            <Aside></Aside>
            <Rightelements></Rightelements>
            <section className="maindbBody">
                <Header></Header>
                <div className="completeProductWrapper">
                    <div className="cpwHead">
                        <h2>Edit Story</h2>
                    </div> 

                    <div className="addProductWrap">
                        <input type="text" value={title} onChange={(e)=>setTitle(e.target.value)} className='usrInpt' placeholder='Story Title' />
                        <select  value={productId} onChange={(e)=>setProductId(e.target.value)} className='usrInpt'>
                            <option>Select Product</option>
                            {
                                products != null?
                                products.map((product,key)=>
                                <option value={product.id} key={key}>{product.title}</option>
                                ) :null
                            } 
                        </select>
                        <label className='textLabel'>Add Video</label>
                        <input type="file" accept="video/*" onChange={(e)=>setVideo(e.target.files[0])} className='usrInpt' />
                        <label className='textLabel'>Add Poster Image</label>
                        <input type="file" accept="image/*" onChange={(e)=>setThumbImage(e.target.files[0])} className='usrInpt' />
                        <button onClick={editStory}>Edit story</button>
                    </div>

                </div> 
            </section>
        </main>     
    </>
  );
}

export default Editstory;
