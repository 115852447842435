import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Login() {
  library.add(faCheckCircle, faTimes);

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [animateSuccessAlert,setAnimateSuccessAlert] = useState("messageContain successMsg")
  const [errorAlert, setErrorAlert] = useState('logmain')

  const navigate = useNavigate();

  function signIn() {
      let item={username, password}

      const formData = new FormData();
      formData.append('username', item.username);
      formData.append('password', item.password);

      const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/admin/login`, requestOptions)
      .then((response) => response.json())
      .then(function(data){ 
          console.log(data)
          if(data.msg === 'success'){
            setAnimateSuccessAlert("messageContain successMsg animate"); 
            setTimeout(function() {
              setAnimateSuccessAlert("messageContain successMsg"); 
              navigate('/admin/dashboard');
            }, 2000);  
            sessionStorage.setItem('username', username);
          } 
          if(data.msg === 'wrong_credential'){
            setErrorAlert('logmain errorAlrt');
            setUsername('');            
            setPassword('');
            setTimeout(function() {
              setErrorAlert('logmain');
            }, 500); 
          }   
      })
  }

  function closeAlrt(){
    setAnimateSuccessAlert("messageContain successMsg");
  }
    return (
      <div className="completeLogWrap">
        <span className="goLink">You have no account? <Link to="/admin/registration">Register Now</Link></span>
        <div className="logContainWrap">
            <div className={errorAlert}>
                <h1>Login Now
                    <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small>
                </h1>
                <div className="mainLogForm">
                    <input type="text" value={username} onChange={(e)=>setUsername(e.target.value)} className="userfld" placeholder="Enter Username" />
                    <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="userfld" placeholder="Enter Username" />
                    <button onClick={signIn} className="subBttn">Login Now</button>
                </div>
            </div>

          <div className={animateSuccessAlert}>
            <FontAwesomeIcon icon="fa-check-circle" className="mainIcon" /> Loged In Successfully <span className="closeMsg" onClick={closeAlrt}><FontAwesomeIcon icon="fa-times" /></span>
          </div>

        </div>
      </div>
    );
  }
  
  export default Login;