import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
function Editcategories() {
  const { id } = useParams();
  const [title, setTitle] = useState('');

  const navigate = useNavigate();

  useEffect(()=>{
    const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${window.token}`,
      },
    };      
    fetch(`${window.url}/admin/CategoryDetails/${id}`, requestOptions)
      .then((result)=>{
        result.json().then((resp)=>{
          setTitle(resp.categoryDetails.title)
        })
      });
  },[]);

  function editCategory(){
    let item={title}
    const formData = new FormData();
    formData.append('id', id);
    formData.append('title', item.title);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
        body: formData
    };
    fetch(`${window.url}/admin/editCategory`, requestOptions)
    .then((response) => response.json())
    .then(function(data){ 
        if(data.msg === "category_updated"){
            navigate('/admin/categories');
        }           
    })
  }

    return (
      <>  
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            <div className="completeProductWrapper">
              <div className="cpwHead">
                <h2>Edit Cetegory</h2>
              </div>
              <div className="editCatBox">
                <input className='editCat' type="text" value={title} onChange={(e)=>setTitle(e.target.value)} placeholder='Edit Category' />
                <button onClick={editCategory}>Edit</button>
              </div>
            </div>

          </section>          
        </main>                    
      </>
    );
  }
  
  export default Editcategories;