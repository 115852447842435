import { useEffect, useState } from "react";
import { Oval } from 'react-loader-spinner';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Storydetails() {
    library.add(faArrowLeft);
    const { id } = useParams();
    const [storyDetails, setStoryDetails] = useState('');
    const [loadingScreen, setLoadingScreen] = useState(false);
    useEffect(()=>{
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };
        Promise.all([
            fetch(`${window.url}/storyDetails/${id}`, requestOptions)
        ]).then(([storyDetails])=>
            Promise.all([storyDetails.json()])
        ).then((data) => {
            setStoryDetails(data[0]);
        })

        setLoadingScreen(true);
        setTimeout(function() {
          setLoadingScreen(false);
        }, 1000);

    }, [id]); 

    function storeLastVisit(product_id){
      const tokenString = sessionStorage.getItem('user_id');
      const formData = new FormData();
      formData.append('user_id', tokenString);    
      formData.append('product_id', product_id);    
      const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/addRecentViews`, requestOptions)
      .then((response) => response.json())
      .then(function(data){
          console.warn(data)
      })
    }



    return (
      <>

        {loadingScreen ? 
            <section className="loadingScreen">
            <span className="loader">
            <Oval
                ariaLabel="loading-indicator"
                height={100}
                width={100}
                strokeWidth={5}
                strokeWidthSecondary={2}
                color="#ffc659"
                secondaryColor="#f7f7f7"
            />
            </span>
            </section>:null
        }

        { 
            storyDetails.storyDetails != null ?
            <div className="storyDetailsWrap">
                <Link className="backBttn" to='/'><FontAwesomeIcon icon="fa-arrow-left" /></Link>
                <video src={storyDetails.storyDetails.video} autoPlay loop controls disablePictureInPicture controlsList="nodownload noremoteplayback noplaybackrate"></video>
                <article>
                    <h2>{storyDetails.storyDetails.title}</h2>
                    <Link onClick={() => storeLastVisit(storyDetails?.storyDetails?.product_id)} to={'/app/productdetails/'+storyDetails.storyDetails.product_id}>View Product</Link>
                </article>
            </div>: null
        }
      </>
    );
  }
  
  export default Storydetails;
  