import { NavLink } from "react-router-dom";
import homeIcon from '../../images/footerHome.svg'
import offerIcon from '../../images/footerOffer.svg'
import profileIcon from '../../images/footerProfile.svg'
import cartIcon from '../../images/footerCart.svg'
import menuIcon from '../../images/footerMenu.svg'

function Footer() {
    return (
      <>
        <footer>
            <NavLink to="/"><img src={homeIcon} alt="" /></NavLink>
            <NavLink to="/app/categoriespop"><img src={menuIcon} alt="" /></NavLink>
            <NavLink to="/app/profile"><img src={profileIcon} alt="" /></NavLink>
            <NavLink to="/app/cart"><img src={cartIcon} alt="" /></NavLink>
            <NavLink to="/app/offer"><img src={offerIcon} alt="" className="oferIcn" /></NavLink>
        </footer>   
      </>
    );
  }
  
  export default Footer;
  