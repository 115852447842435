import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
function Categorylist() {
    const [categoryList, SetcategoryList] = useState('');

    useEffect(()=>{
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };  
        Promise.all([
            fetch(`${window.url}/categoryList`, requestOptions),
        ]).then(([categoryList])=>
            Promise.all([categoryList.json()])
        ).then((data) => {
            SetcategoryList(data[0]);
        })
    },[]);

    return (
      <>
        <section className="catListWrap">
            {Object.values(categoryList).reverse().map((category, key) => {
                return (
                  <Link to={'/app/productlist/'+category.id} className="catBox" key={key}>{category.title}</Link>
                );
            })} 
        </section>
      </>
    );
  }
  
  export default Categorylist;
  