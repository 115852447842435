import Footer from "./components/Footer";
import Mainheader from "./components/Mainheader";
import Stories from "./components/Stories";
import Categorylist from "./components/Categorylist";
import Trendingtoday from "./components/Trendingtoday";
import Recentproducts from "./components/Recentproducts";
import { Link } from "react-router-dom";
import slide_01 from "../../src/images/topSlide.svg";
import slide_02 from "../../src/images/topSlide2.svg";
import Offerslide from "./components/Offerslide";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

/********** Loading **********/

import { Oval } from 'react-loader-spinner';
import { useState, useEffect } from 'react';
function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const [loadingScreen, setLoadingScreen] = useState(false);

  useEffect(()=>{
    setLoadingScreen(true);
    setTimeout(function() {
      setLoadingScreen(false);
    }, 500);
  }, []); 
  return (
    <>  
      {loadingScreen ? 
        <section className="loadingScreen">
          <span className="loader">
            <Oval
              ariaLabel="loading-indicator"
              height={100}
              width={100}
              strokeWidth={5}
              strokeWidthSecondary={2}
              color="#ffc659"
              secondaryColor="#f7f7f7"
            />
          </span>
        </section>:null
      }

      <section className="bodyHome">
        <Mainheader></Mainheader>
        <Stories></Stories>
        <section className="appHomePageWrap">
          <div className="mainSlideWrap">
            <Carousel responsive={responsive} removeArrowOnDeviceType={["tablet", "mobile"]} infinite={true} autoPlay={true}>
              <Link><picture><img src={slide_01} alt="" /></picture></Link>
              <Link><picture><img src={slide_02} alt="" /></picture></Link>
            </Carousel>
          </div>
        </section>  
        <Categorylist></Categorylist> 
        <Trendingtoday></Trendingtoday>
        <Offerslide></Offerslide>
        <Recentproducts />
        <Footer></Footer>
      </section>
    </>
  );
}

export default Home;
