import { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faHeart, faSearch, faPlus, faFilter, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Trendingtoday() {
  library.add(faArrowLeft, faHeart, faSearch, faPlus, faFilter, faShoppingCart);
  const [productListing, setProductListing] = useState('');

  useEffect(()=>{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
        };
        Promise.all([
            fetch(`${window.url}/admin/fetchProducts`, requestOptions),
        ]).then(([productListing])=>
            Promise.all([productListing.json()])
        ).then((data) => {
          setProductListing(data[0]);
        })
  }, []); 

  function storeLastVisit(id){
    const tokenString = sessionStorage.getItem('user_id');
    const formData = new FormData();
    formData.append('user_id', tokenString);    
    formData.append('product_id', id);    
    const requestOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${window.token}`,
        },
        body: formData
    };
    fetch(`${window.url}/addRecentViews`, requestOptions)
    .then((response) => response.json())
    .then(function(data){
        console.warn(data)
    })
  }




  return (
    <>
        <section className="TrendingSlot">
            <h4>Trending for today</h4>
            <div className="tpScroll">

                {Object.values(productListing).reverse().map((product, key) => {
                    return (
                        <div className="tpBox" key={key}>
                            <picture>
                                <Link to={'/app/productdetails/'+product?.id}>
                                <img src={product.images} alt="" />
                                </Link>
                            </picture>
                            <h3>
                                <small>{product?.title}</small>
                                ₹ {product?.price}
                            </h3>  
                            <Link className="productDetailsbttn" onClick={() => storeLastVisit(product?.id)} to={'/app/productdetails/'+product?.id}><FontAwesomeIcon icon='fa-plus'/></Link>
                        </div>
                    );
                })}

            </div>
        </section>        
    </>
  );
}

export default Trendingtoday;