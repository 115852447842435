import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import $ from 'jquery'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faTrashCan, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Categories() {
    library.add(faPlus, faTrashCan, faPencil);

  const [categoryList, setCategoryList]=useState('');

  const navigate = useNavigate();

  function afterDelete(){
    const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${window.token}`,
      },
    };      
    fetch(`${window.url}/admin/fetchCategory`, requestOptions)
      .then((result)=>{
        result.json().then((resp)=>{
          setCategoryList(resp)
        })
    });
  }

  useEffect(()=>{
    const tokenString = sessionStorage.getItem('username');
    if(tokenString == null){
      navigate('/admin/login');
    }
    afterDelete()   

  },[navigate]);

  function handleRemove(id){
    const formData = new FormData();
        formData.append('id', id);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/admin/deleteCategory`, requestOptions)
      .then((response) => response.json())
      .then(function(data){        
        afterDelete()
      }) 
  }

  $('.catBox .delete').click(function(){
    $(this).parent().find('.catBoxDeleteWrap').fadeIn(300);
  });
  $('.sureToDelete, .cancel').click(function(){
    $('.catBoxDeleteWrap').fadeOut(300);
  });

  return (
    <>
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            
            <div className="caregoriesListing">
              <div className="cpwHead">
                <h2>All Categories</h2>
                <span className="headbttnsWrp">
                  <Link to='/admin/addcategories' className='addBttn'><FontAwesomeIcon icon="fa-plus" /></Link>
                </span>
              </div>

                {Object.values(categoryList).map((category, key) => {
                    return (
                    <div className='catBox' key={key}>
                        <h4>{category.title}</h4>
                        <span className="editIcon"><Link to={'/admin/editcategories/'+category.id}><FontAwesomeIcon icon="fa-pencil" /></Link></span>
                        <button className='delete'><FontAwesomeIcon icon="fa-trash-can" /></button>
                        <div className="catBoxDeleteWrap">
                          <div className="confBox">
                            <h4>Sure to delete this category</h4>
                            <span>
                              <button className='sureToDelete' onClick={() => handleRemove(category.id)}>Sure</button>
                              <button className='cancel'>Cancel</button>
                            </span>
                          </div>
                        </div>
                    </div>
                    );
                })}


            </div>

          </section>          
        </main>            
    </>
  );
}

export default Categories;
