import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
function Editsubcategories() {
  const { id } = useParams();
  const [title, setTitle] = useState('');

  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [Categories, setCategories] = useState('');
  const [subcategoryDetails, setSubcategoryDetails] = useState('');

  useEffect(()=>{     
    const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${window.token}`,
      },
    };   
    Promise.all([
        fetch(`${window.url}/admin/fetchCategory`, requestOptions),
        fetch(`${window.url}/admin/subCategoryDetails/${id}`, requestOptions)
    ]).then(([categoryId, subcategoryDetails])=>
        Promise.all([categoryId.json(), subcategoryDetails.json()])
    ).then((data) => {
      setCategories(data[0]);
      setSubcategoryDetails(data[1]);
      setTitle(data[1].subcategory.title);
      setSelectedCategoryId(data[1].subcategory.cat_id);
    })
      
  },[]);


  function editSubCategory(){
    let item={title, selectedCategoryId}
    const formData = new FormData();
    formData.append('id', id);
    formData.append('title', item.title);
    formData.append('cat_id', item.selectedCategoryId);
    

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
        body: formData
    };
    fetch(`${window.url}/admin/editSubCategory`, requestOptions)
    .then((response) => response.json())
    .then(function(data){
      console.log(data)  
    })
  }

    return (
      <>  
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            <div className="completeProductWrapper">
              <div className="cpwHead">
                <h2>Edit Sub Category</h2>
              </div>

              { subcategoryDetails.subcategory != null ?
              <div className="editCatBox">
                
                <select value={selectedCategoryId} className='changeCategory' onChange={(e)=>setSelectedCategoryId(e.target.value)}>
                    <option>Select Categories</option>
                    {Object.values(Categories).map((category, key) => {
                        return (
                            <option value={category.id} key={key}>{category.title}</option>
                        );
                    })}
                </select>

                <input className='editCat' type="text" value={title} onChange={(e)=>setTitle(e.target.value)} placeholder='Edit Sub Category' />
                <button onClick={editSubCategory}>Edit</button>
              </div> : null
              }
              
            </div>

          </section>          
        </main>                    
      </>
    );
  }
  
  export default Editsubcategories;