import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
function Dashboard() {

  const navigate = useNavigate();
  useEffect(()=>{
    const tokenString = sessionStorage.getItem('username');
    if(tokenString == null){
      navigate('/admin/login');
    }
    console.warn(tokenString)
  },[navigate]);

  return (
    <>
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            
          </section>          
        </main>            
    </>
  );
}

export default Dashboard;
