import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
function Products() {
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    // const [productDetails, setProductDetails] = useState([]);
    
    const [categoryId, setCategoryId] = useState([]);
    const [selectedCategoryId, selectedSetCategoryId] = useState([]);
    const [selectedSubCategoryId, selectedSetSubCategoryId] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [price, setPrice] = useState('');

    const [discountAmount, setDiscountAmount] = useState('');
    const [discountImage, setDiscountImage] = useState('');
    const [selectedDiscountImage, setSelectedDiscountImage] = useState('');
    const [discountFlag, setDiscountFlag] = useState(0);


    const [showOfferBox, setShowOfferBox] = useState(false);
    const [addBttn, setAddBttn] = useState(true);

    // const [fetchSubcategory, setFetchSubcategory] = useState('');

  const navigate = useNavigate();
    

    function getPrevSubCatId(cat_id){
      const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
      };

      fetch(`${window.url}/admin/fetchSubCategoryByCategory/${cat_id}`, requestOptions)
      .then((response) => response.json())
      .then(function(data){   
        setSubCategory(data.subcategories)
      })   
    }

    useEffect(()=>{
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };
        Promise.all([
            fetch(`${window.url}/admin/fetchCategory`, requestOptions),
            fetch(`${window.url}/admin/productDetails/${id}`, requestOptions)
        ]).then(([categoryId, productDetails])=>
            Promise.all([categoryId.json(), productDetails.json()])
        ).then((data) => {
            setCategoryId(data[0]);
            setTitle(data[1].productDetails.title)
            setDescription(data[1].productDetails.description)
            setImage(data[1].productDetails.images)
            setPrice(data[1].productDetails.price)
            selectedSetCategoryId(data[1].productDetails.cat_id)
            getPrevSubCatId(data[1].productDetails.cat_id);
            selectedSetSubCategoryId(data[1].productDetails.sub_cat_id)
            selectedSetCategoryId(data[1].productDetails.cat_id)
            setDiscountAmount(data[1].productDetails.discount)
            setDiscountImage(data[1].productDetails.discount_image)
        })

      }, [id]);      

    function handelImage(select_thumb){
      setImage(URL.createObjectURL(select_thumb));
      setSelectedImage(select_thumb)
    }

    function discountImageChange(disImg){
      setDiscountImage(URL.createObjectURL(disImg));
      setSelectedDiscountImage(disImg)
    }

    function editproduct(){
        let item={title, description, selectedImage,  selectedCategoryId, selectedSubCategoryId, price}
        const formData = new FormData();
        formData.append('id', id);
        formData.append('title', item.title);
        formData.append('description', item.description);
        formData.append('image', item.selectedImage);
        formData.append('cat_id', item.selectedCategoryId);
        formData.append('sub_cat_id', item.selectedSubCategoryId);
        formData.append('price', item.price);

        formData.append('discount', discountAmount);
        formData.append('discount_image', selectedDiscountImage);
        formData.append('discount_flag', discountFlag);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        // setImage(URL.createObjectURL(selectedImage));
        fetch(`${window.url}/admin/editProduct`, requestOptions)
        .then((response) => response.json())
        .then(function(data){ 
            console.log(data)  
            if(data.msg === "product_updated"){
                navigate(-1);
            }           
        })

    } 
    
    function HandleCategoryChange(catId){
      selectedSetCategoryId(catId)

      const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
      };

      fetch(`${window.url}/admin/fetchSubCategoryByCategory/${catId}`, requestOptions)
      .then((response) => response.json())
      .then(function(data){ 
        setSubCategory(data.subcategories)
      }).then(function(data){ 
        console.log(subCategory)
      })
    }

    function offerBox(){
      setShowOfferBox(true)
      setAddBttn(false)
      setDiscountFlag(1)
    }
    function hideOffer(){
      setShowOfferBox(false)
      setAddBttn(true)
      setDiscountFlag(0)
    }

    return (
      <>  
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            <div className="completeProductWrapper">
              <div className="cpwHead">
                <h2>Edit Product</h2>
                <span className="headbttnsWrp">
                </span>
              </div>

                  <div className="addProductWrap">
                      <input type="text" value={title} onChange={(e)=>setTitle(e.target.value)} className='usrInpt' placeholder='Product Title' />
                      <input type="text" value={description} onChange={(e)=>setDescription(e.target.value)} className='usrInpt' placeholder='Product Description' />
                      
                      <label className="typePhotoInput">
                        <b>{image}</b>
                        <input type="file" onChange={(e)=>handelImage(e.target.files[0])}/>  
                        <picture><img src={image} alt="" /></picture>
                      </label>

                      <select value={selectedCategoryId || []} onChange={(e)=>HandleCategoryChange(e.target.value)} className='usrInpt'>
                        <option>Select Category</option>
                          {
                            categoryId != null?
                            categoryId.map((category,key)=>
                            <option value={category.id} key={key}>{category.title}</option>
                            ) :null
                          }  
                      </select>

                      <select value={selectedSubCategoryId || []} onChange={(e)=>selectedSetSubCategoryId(e.target.value)} className='usrInpt'>
                        <option>Select Subcategory</option>
                          {
                            subCategory != null?
                            subCategory.map((subCategory,key)=>
                            <option value={subCategory.id} key={key}>{subCategory.title}</option>
                            ) :null
                          }                         
                      </select>

                      <input type="number" value={price} onChange={(e)=>setPrice(e.target.value)} className='usrInpt' placeholder='Price' />

                      {showOfferBox ?
                        <section className="offerBoxWrap">
                          <h2>Offer</h2>
                          <label className='offerImg'>
                            <img src={discountImage} alt="" className={discountImage != null ? 'show' : 'hide' } />
                            <input type="file" className='usrInpt' onChange={(e)=>discountImageChange(e.target.files[0])} accept='image/*' placeholder='Offer image' />
                            <b>Edit Image</b>
                          </label>    
                          <input type="text" className='usrInpt' value={discountAmount} onChange={(e)=>setDiscountAmount(e.target.value)} placeholder='Offer Amount' />
                          <h2>Final Price: {price - (( discountAmount / 100) * price)}</h2> 
                          <p onClick={hideOffer}>hide Offer</p>
                        </section>:null
                      }
                      
                      <button onClick={editproduct}>Edit Product</button>
                      {addBttn ? <button className='OfferBttn' onClick={offerBox}>Edit Offer</button>:null}
                  </div>
                


            </div>

          </section>          
        </main>                    
      </>
    );
  }
  
  export default Products;