import '../src/admin_style/adminMain.css';
import '../src/app_style/appmain.css'
import { Route, Routes } from "react-router-dom";

/********  admin Pages  *******/
import Login from './adminPanel/Login';
import Registration from './adminPanel/Registration';
import Dashboard from './adminPanel/Dashboard';
import Products from './adminPanel/Products';
import Addproduct from './adminPanel/Addproduct';
import Editproduct from './adminPanel/Editproduct';
import Categories from './adminPanel/Categories';
import Addcategories from './adminPanel/Addcategories';
import Addsubcategories from './adminPanel/Addsubcategories';
import Subcategories from './adminPanel/Subcategories';
import Editcategories from './adminPanel/Editcategories';
import Editsubcategories from './adminPanel/Editsubcategories';
import Storylist from './adminPanel/Storylist';
import Addstory from './adminPanel/Addstory';
import Editstory from './adminPanel/Editstory';
import Discounts from './adminPanel/Discounts';
import Coupon from './adminPanel/Coupon';
import Addcoupon from './adminPanel/Addcoupon';
import Editcoupon from './adminPanel/Editcoupon';
import Orders from './adminPanel/Orders';
import Orderdetails from './adminPanel/Orderdetails';

/********  appPages  *******/
import Home from './app/Home';
import Profile from './app/Profile';
import Applogin from './app/Applogin';
import Appregister from './app/Appregister';
import Storydetails from './app/Storydetails';
import Productlist from './app/Productlist';
import Productdetails from './app/Productdetails';
import Wishlist from './app/WishList';
import Cart from './app/Cart';
import Categoriespop from './app/components/Categoriespop';
import Offer from './app/Offer';
import Checkout from './app/Checkout';
import Payment from './app/Payment';
import Addlocation from './app/Addlocation';
import EditDeliveryLocation from './app/EditDeliveryLocation';
import Orderlist from './app/Orderlist';
import Seeallorders from './app/Seeallorders';

/********** Loading **********/

import { Oval } from 'react-loader-spinner';
import { useState, useEffect } from 'react';


function App() {

  const [loadingScreen, setLoadingScreen] = useState(false);

  useEffect(()=>{
    setLoadingScreen(true);
    setTimeout(function() {
      setLoadingScreen(false);
    }, 1000);
  }, []); 



  return (
    <div className="completeApp">

      {loadingScreen ? 
        <section className="loadingScreen">
          <span className="loader">
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            strokeWidthSecondary={2}
            color="#ffc659"
            secondaryColor="#f7f7f7"
          />
          </span>
        </section>:null
      }

      
    <Routes>

      { /********  admin Pages  *******/ }
      <Route path="admin/login/" element={<Login />} />
      <Route path="admin/registration" element={<Registration />} />
      <Route path="admin/dashboard" element={<Dashboard />} />
      <Route path="admin/products" element={<Products />} />
      <Route path="admin/storylist" element={<Storylist />} />
      <Route path="admin/addstory" element={<Addstory />} />
      <Route path="admin/addproduct" element={<Addproduct />} />
      <Route path="admin/addcategories" element={<Addcategories />} />
      <Route path="admin/addsubcategories" element={<Addsubcategories />} />
      <Route path="admin/categories" element={<Categories />} />
      <Route path="admin/subcategories" element={<Subcategories />} />
      <Route path="admin/editproduct/:id" element={<Editproduct />} />
      <Route path="admin/editcategories/:id" element={<Editcategories />} />
      <Route path="admin/editsubcategories/:id" element={<Editsubcategories />} />
      <Route path="admin/editstory/:id" element={<Editstory />} />
      <Route path="admin/discounts" element={<Discounts />} />
      <Route path="admin/coupon" element={<Coupon />} />
      <Route path="admin/addcoupon" element={<Addcoupon />} />
      <Route path="admin/editcoupon/:id" element={<Editcoupon />} />
      <Route path="admin/orders" element={<Orders />} />
      <Route path="admin/orderdetails/:id" element={<Orderdetails />} />

      { /********  appPages  *******/ }  
      {/* <Route path="*" element={<Home />} />     */}
      <Route path="/" element={<Home />} />
      <Route path="/app/profile" element={<Profile />} />
      <Route path="/app/login" element={<Applogin />} />
      <Route path="/app/register" element={<Appregister />} />
      <Route path="/app/wishlist" element={<Wishlist />} />
      <Route path="/app/cart" element={<Cart />} />
      <Route path="/app/offer" element={<Offer />} />
      <Route path="/app/categoriespop" element={<Categoriespop />} />
      <Route path="/app/storydetails/:id" element={<Storydetails />} />
      <Route path="/app/productlist/:id" element={<Productlist />} />
      <Route path="/app/productdetails/:id" element={<Productdetails />} />
      <Route path="/app/checkout" element={<Checkout />} />
      <Route path="/app/payment" element={<Payment />} />
      <Route path="/app/addlocation" element={<Addlocation />} />
      <Route path="/app/orderlist" element={<Orderlist />} />
      <Route path="/app/editDeliveryLocation/:id" element={<EditDeliveryLocation />} />
      <Route path="/app/seeallorders/:id" element={<Seeallorders />} />

    </Routes>
    </div>
  );
}

export default App;
