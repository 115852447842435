import Footer from "./components/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { useState, useEffect  } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SwipeToDelete from 'react-swipe-to-delete-component';
import 'react-swipe-to-delete-component/dist/swipe-to-delete.css';
function Wishlist() {
    library.add( faArrowLeft, faArrowRight, faSearch );
    const navigate = useNavigate();

    const [wishList, setWishList] = useState('');
    const [shortAlert, setShortAlert] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);

    useEffect(()=>{
        const tokenString = sessionStorage.getItem('user_id');
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };
        Promise.all([
            fetch(`${window.url}/wishListOfUser/${tokenString}`, requestOptions)
        ]).then(([wishList])=>
            Promise.all([wishList.json()])
        ).then((data) => {
            setWishList(data[0]);
        })
        setLoadingScreen(true);
        setTimeout(function() {
          setLoadingScreen(false);
        }, 500);
    },[]); 

    function swipeTodelete(id){
        const tokenString = sessionStorage.getItem('user_id');
        
        const formData = new FormData();
            formData.append('product_id', id);
            formData.append('user_id', tokenString);

            const requestOptions = {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${window.token}`,
              },
              body: formData
          };
          fetch(`${window.url}/removeFromWishList`, requestOptions)
          .then((response) => response.json())
          .then(function(data){   
            // console.warn(data)
            if(data.error === 0){
                setShortAlert(true);
                setTimeout(function() {
                  setShortAlert(false);
                }, 3000);
            }  
        }) 
    }

    function search(){
        setShowSearch(true)
    }
    function hideSearch(){
        setShowSearch(false)
    }


  return (
    <>

    {loadingScreen ? 
      <section className="loadingScreen">
        <span className="loader">
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            strokeWidthSecondary={2}
            color="#ffc659"
            secondaryColor="#f7f7f7"
          />
        </span>
      </section>:null
    }
        {showSearch ? 
        <section className="searchWrapSingle">
            <span className='closeSearch' onClick={hideSearch}><FontAwesomeIcon icon="fa-arrow-left" /></span>
            <input type="text" placeholder='Search...' className='searchBox' onChange={(event) => {setSearchTerm(event.currentTarget.value);}} />
        </section>:null
        }
        <section className="productListHead">
            <Link className="backBttn"  onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
            <span className="rightWigt">
                <button onClick={search}><FontAwesomeIcon icon="fa-search" /></button>
            </span>
            <h4 className="wishListTxt">Wishlist</h4>
        </section>

        <h4 className="noItems">No Item Found in Wishlist</h4>

        { wishList?.wsihLists != null ?
            <div className="wishListTotalWrap">
                {
                  wishList?.wsihLists.filter((wsihLists) => {
                    if(searchTerm === ""){
                      return wsihLists
                    }else if (wsihLists.title.toLowerCase().includes(searchTerm.toLowerCase())){
                      return wsihLists
                    }
                  }).map((wishBox,key)=>{
                    return (
                    <SwipeToDelete onDelete={() => swipeTodelete(wishBox.id)}  key={key}>  
                        <div className="listBox">
                            <picture><img src={wishBox.images} alt="" /></picture> 
                            <h4>{wishBox.title}
                            <small>Rs: {wishBox.price}</small></h4>
                            <Link to={'/app/productdetails/'+wishBox?.id} className="goToProduct"><FontAwesomeIcon icon="fa-arrow-right" /></Link>
                        </div>
                    </SwipeToDelete >
                    )})
                }
            </div>: null
        }

        {shortAlert ?
          <span className="errAlert wishRemove">Removed</span>:null
        }

        <Footer></Footer>
    </>
  );
}

export default Wishlist;
