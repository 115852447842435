import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function Stories() {

  const [stories, setStories] = useState('')

  useEffect(()=>{
      const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
      };  
      Promise.all([
          fetch(`${window.url}/stories`, requestOptions),
      ]).then(([stories])=>
          Promise.all([stories.json()])
      ).then((data) => {
        setStories(data[0]);
      })
  },[]);
    return (
      <>
        { stories.stories != null ?
          <section className="storyListWrap">
              {Object.values(stories.stories).reverse().map((story, key) => {
                return (
                  <Link to={'/app/storydetails/'+story.id} key={key}>
                    <img src={story.thumb_image} alt="" />                    
                  </Link>
                );
              })} 
          </section>: null
        }
      </>
    );
  }
  
  export default Stories;
  