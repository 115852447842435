import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faTrashCan, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Coupon() {
  library.add(faPlus, faTrashCan, faPencil);
  const [couponList, setCouponList] = useState('');

  function afterDelete(){
    const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${window.token}`,
      },
    };      
    fetch(`${window.url}/admin/couponList`, requestOptions)
      .then((result)=>{
        result.json().then((resp)=>{
          setCouponList(resp)
        })
    });
  }

  useEffect(()=>{ 
    afterDelete()
  },[]);

  function handleRemove(id){
    const formData = new FormData();
        formData.append('id', id);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/admin/deleteCoupon`, requestOptions)
      .then((response) => response.json())
      .then(function(data){        
        afterDelete()
      }) 
  }

  $('.catBox .delete').click(function(){
    $(this).parent().find('.catBoxDeleteWrap').fadeIn(300);
  });
  $('.sureToDelete, .cancel').click(function(){
    $('.catBoxDeleteWrap').fadeOut(300);
  });

  return (
    <>
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            
            <div className="caregoriesListing">
              <div className="cpwHead">
                <h2>Coupons</h2>
                <span className="headbttnsWrp">
                  <Link to='/admin/addcoupon' className='addBttn'><FontAwesomeIcon icon="fa-plus" /></Link>
                </span>
              </div>
              { couponList?.couponList != null ?
                <div className="couponBoxWrap">
                  {Object.values(couponList?.couponList).reverse().map((couponBox, key) => {
                      return (
                      <div className='catBox' key={key}>
                          <h4>Use coupon: <b>{couponBox?.coupon}</b> for <b>{couponBox?.discount}</b>% discount on <b>{couponBox?.category_name}</b>
                            <small>{couponBox?.start_date}  To  {couponBox?.end_date}</small>
                          </h4>
                          <span className="editIcon"><Link to={'/admin/editcoupon/'+couponBox?.id}><FontAwesomeIcon icon="fa-pencil" /></Link></span>
                          <button className='delete'><FontAwesomeIcon icon="fa-trash-can" /></button>
                          <div className="catBoxDeleteWrap">
                            <div className="confBox">
                              <h4>Sure to delete this category</h4>
                              <span>
                                <button className='sureToDelete' onClick={() => handleRemove(couponBox?.id)}>Sure</button>
                                <button className='cancel'>Cancel</button>
                              </span>
                            </div>
                          </div>
                      </div>
                      );
                  })}
                </div>:null
              }




            </div>

          </section>          
        </main>            
    </>
  );
}

export default Coupon;
