import { Link } from "react-router-dom";
import Recentproducts from "../components/Recentproducts";
import { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faUser, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/Footer';
import Offerslide from "./Offerslide";
function Categoriespop() {
    library.add( faArrowLeft, faUser, faShoppingCart );
    const [loggedUser, setLoggedUser] = useState(null);
    const [categoryList, SetcategoryList] = useState('');

    useEffect(()=>{
      const tokenString = sessionStorage.getItem('user_id');
      if(tokenString != null){
        setLoggedUser(tokenString);  
      }

      const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
      };  
      Promise.all([
          fetch(`${window.url}/categoryList`, requestOptions),
      ]).then(([categoryList])=>
          Promise.all([categoryList.json()])
      ).then((data) => {
          SetcategoryList(data[0]);
      })




    },[]);
    function distroySeson(){
      sessionStorage.clear()
    }
    return (
      <>      
          <section className="categoriesPop">
            <div className="cathead">
              <button className="backBttn" >All Categories</button>
              <span className="rightWigt">
                <Link to="/app/profile"><FontAwesomeIcon icon="fa-user" /></Link>
                <Link to="/app/cart"><FontAwesomeIcon icon="fa-shopping-cart" /></Link>
                <Link  onClick={distroySeson} to='/app/Login'>{  loggedUser != null ? 'Logout' : 'Login'}</Link>
              </span>
            </div>
            <div className="categoryListing">
              {Object.values(categoryList).map((category, key) => {
                  return (
                    <Link to={'/app/productlist/'+category.id} className="catBox" key={key}>{category.title}</Link>
                  );
              })} 
            </div>
            <Recentproducts></Recentproducts>
            <Offerslide></Offerslide>
          </section>  
          <Footer></Footer>
      </>
    );
  }
  
  export default Categoriespop;
  