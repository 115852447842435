import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { Link } from 'react-router-dom';
import $ from 'jquery'
import { useEffect, useState } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faTrashCan, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Discounts() {
    library.add(faPlus, faTrashCan, faPencil);
    const [discounts, setDiscounts] = useState('');

    function fetchOfferList(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
        };      
        fetch(`${window.url}/admin/offers`, requestOptions)
            .then((result)=>{
            result.json().then((resp)=>{
                setDiscounts(resp)
            })
        });
    }

    useEffect(()=>{
        fetchOfferList()
    }, []); 

    function handleRemove(id) {
        const formData = new FormData();
            formData.append('product_id', id);
            const requestOptions = {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${window.token}`,
              },
              body: formData
          };
          fetch(`${window.url}/admin/removeDiscount`, requestOptions)
          .then((response) => response.json())
          .then(function(data){        
            fetchOfferList()
          }) 
      }

    $('.delete').click(function(){
      $(this).parent().find('.deleteplBoxcontain').addClass('active');
      $(this).parent().nextAll().find('.deleteplBoxcontain').removeClass('active');
      $(this).parent().prevAll().find('.deleteplBoxcontain').removeClass('active');
    });
  
    $('.cancel').click(function(){
      $(this).parent().parent().find('.deleteplBoxcontain').removeClass('active');
    });

  return (
    <>
        <main className='adminPanelMainWrap'>
            <Aside></Aside>
            <Rightelements></Rightelements>
            <section className="maindbBody">
                <Header></Header>
                <div className="completeProductWrapper">
                    <div className="cpwHead">
                        <h2>Discounts</h2>
                        <span className="headbttnsWrp">
                        <Link to='/admin/addproduct' className='addBttn'><FontAwesomeIcon icon="fa-plus" /></Link>
                        </span>
                    </div>
                    { discounts?.offers != null ?
                        <div className="productListWrap">                   

                            {Object.values(discounts?.offers).map((discount, key) => {
                                return (
                                <div className='plBox' key={key}>
                                    <picture><img src={discount?.discount_image} alt="" /></picture>
                                    <h4>{discount?.discount}% offer on {discount?.title}
                                        <small>MRP: {discount?.price}</small>
                                    </h4>
                                    <h5 className='price'>Rs. {discount?.final_price}</h5>

                                    <div className="deleteplBoxcontain">
                                        <p>Sure to delete</p>
                                        <button className='sureDelete' onClick={() => handleRemove(discount?.id)}>Yes</button>
                                        <button className='cancel'>No</button>
                                    </div>
                                    <Link to={'/admin/editproduct/'+discount?.id} className='edit'><FontAwesomeIcon icon="fa-pencil" /></Link>
                                    <button className='delete'><FontAwesomeIcon icon="fa-solid fa-trash-can" /></button>
                                </div>
                                );
                            })}
                        </div> :null
                    }
                </div>
            </section>
        </main>     
    </>
  );
}

export default Discounts;