import { Link } from "react-router-dom";
import { useState } from "react";
function Registration() {

    const [name,setName] = useState("")
    const [username,setUsername] = useState("")
    const [password,setPassword] = useState("")
    const [email,setEmail] = useState("")

    function signUp(){
        let item={name, username, password, email}

        const formData = new FormData();
        formData.append('name', item.name);
        formData.append('username', item.username);
        formData.append('email', item.email);
        formData.append('password', item.password);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        fetch(`${window.url}/admin/registration`, requestOptions)
        .then((response) => response.json())
        .then(function(data){ 
            console.log(data)
        })
    }

    return (
      <div className="completeLogWrap">
        <span className="goLink">You have no account? <Link to="/admin/login/">Login Now</Link></span>
        <div className="logContainWrap">
            <div className="logmain">
                <h1>Register Now
                    <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small>
                </h1>
                <div className="mainLogForm">
                    <input type="text" value={username} onChange={(e)=>setUsername(e.target.value)} className="userfld" placeholder="Enter Username" />
                    <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="userfld" placeholder="Enter Password" />
                    <input type="text" value={name} onChange={(e)=>setName(e.target.value)} className="userfld" placeholder="Enter Name" />
                    <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="userfld" placeholder="Enter Email" />
                    {/* <input type="file" className="userfld" placeholder="Enter Email" /> */}
                    <button className="subBttn"  onClick={signUp}>Register Now</button>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Registration;