import logoMain from '../images/logo.svg';
import { Link } from 'react-router-dom';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Header() {
    library.add(faBell);
    return (
      <>
            <header className="adminHeader">
                <span className='logoWrap'><img src={logoMain} alt="" /></span>
                <Link className='bellIcon'><FontAwesomeIcon icon="fa-bell" /></Link>
            </header>      
      </>
    );
  }
  
  export default Header;