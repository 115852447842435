import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
function Addproduct() {
  const { id } = useParams();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [categoryId, setCategoryId] = useState([]);
    const [selectedCategoryId, selectedSetCategoryId] = useState([]);
    const [selectedSubCategoryId, selectedSetSubCategoryId] = useState([]);
    const [price, setPrice] = useState('');
    const [subCategory, setSubCategory] = useState([]);

    const [discountAmount, setDiscountAmount] = useState('');
    const [discountImage, setDiscountImage] = useState('');
    const [discountFlag, setDiscountFlag] = useState(0);


    const [showOfferBox, setShowOfferBox] = useState(false);
    const [addBttn, setAddBttn] = useState(true);

    useEffect(()=>{
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };
     
        Promise.all([
            fetch(`${window.url}/admin/fetchCategory`, requestOptions),
        ]).then(([categoryId])=>
            Promise.all([categoryId.json()])
        ).then((data) => {
          setCategoryId(data[0]);
        })


    }, [id]);

    function HandleCategoryChange(catId){
      selectedSetCategoryId(catId)

      const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },

      };
      fetch(`${window.url}/admin/fetchSubCategoryByCategory/${catId}`, requestOptions)
      .then((response) => response.json())
      .then(function(data){ 
        setSubCategory(data.subcategories)
      }).then(function(data){ 
        console.log(subCategory)
      })
    }

    function addProduct(){
        let item={title, description, image, selectedCategoryId, selectedSubCategoryId, price}

        const formData = new FormData();
        
        formData.append('title', item.title);
        formData.append('description', item.description);
        formData.append('image', item.image);
        formData.append('cat_id', item.selectedCategoryId);
        formData.append('sub_cat_id', item.selectedSubCategoryId);
        formData.append('price', item.price);

        formData.append('discount', discountAmount);
        formData.append('discount_image', discountImage);
        formData.append('discount_flag', discountFlag);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        fetch(`${window.url}/admin/addProduct`, requestOptions)
        .then((response) => response.json())
        .then(function(data){ 
            console.log(data)
            if(data.msg === 'product_added'){
                navigate('/admin/products');
            } 
        })

    }
    const navigate = useNavigate();

    function offerBox(){
      setShowOfferBox(true)
      setAddBttn(false)
      setDiscountFlag(1)
    }
    function hideOffer(){
      setShowOfferBox(false)
      setAddBttn(true)
      setDiscountFlag(0)
    }



    return (
      <>  
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            <div className="completeProductWrapper">
              <div className="cpwHead">
                <h2>Add Products</h2>
                <span className="headbttnsWrp">
                </span>
              </div>

                <div className="addProductWrap">
                    <input type="text" value={title} onChange={(e)=>setTitle(e.target.value)} className='usrInpt' placeholder='Product Title' />
                    <input type="text" value={description} onChange={(e)=>setDescription(e.target.value)} className='usrInpt' placeholder='Product Description' />
                    <input type="file" onChange={(e)=>setImage(e.target.files[0])} className='usrInpt' />                    
                      <select onChange={(e)=>HandleCategoryChange(e.target.value)} className='usrInpt'>
                        <option>Select Category</option>
                        {
                          categoryId != null?
                          categoryId.map((category,key)=>
                          <option value={category.id} key={key}>{category.title}</option>
                          ) :null
                        }                      
                      </select>

                    <select className='usrInpt' onChange={(e)=>selectedSetSubCategoryId(e.target.value)}>
                      <option>Select Sub Category</option>
                      {
                        subCategory != null?
                        subCategory.map((subCategory,key)=>
                        <option value={subCategory.id} key={key}>{subCategory.title}</option>
                        ) :null
                      }                 
                    </select>
                    <input type="number" value={price} onChange={(e)=>setPrice(e.target.value)} className='usrInpt' placeholder='Price' />

                    {showOfferBox ?
                      <section className="offerBoxWrap">
                        <h2>Offer</h2>
                        <input type="file" className='usrInpt' onChange={(e)=>setDiscountImage(e.target.files[0])} accept='image/*' placeholder='Offer image' />
                        <input type="text" className='usrInpt' value={discountAmount} onChange={(e)=>setDiscountAmount(e.target.value)} placeholder='Offer Amount' />
                        <h2>Final Price: {price - (( discountAmount / 100) * price)}</h2> 
                        <p onClick={hideOffer}>Remove Offer</p>
                      </section>:null
                    }



                    <button onClick={addProduct}>Add Product</button>
                    {addBttn ? <button className='OfferBttn' onClick={offerBox}>Add Offer</button>:null}
                    
                </div>

            </div>

          </section>          
        </main>                    
      </>
    );
  }
  
  export default Addproduct;