import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import $ from 'jquery'


import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faTrashCan, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Subcategories() {
  library.add(faPlus, faTrashCan, faPencil);

  const [subcategoryList, setSubcategoryList] = useState(''); 
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [Categories, setCategories] = useState('');

  const [showSubCategory, setShowSubCategory] = useState(false); 

  useEffect(()=>{
    const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${window.token}`,
      },
    };            
    Promise.all([
        fetch(`${window.url}/admin/fetchCategory`, requestOptions),
        fetch(`${window.url}/admin/fetchSubCategoryByCategory/${sessionStorage.selectedCategoryId}`, requestOptions)
    ]).then(([categoryId, subcategoryList])=>
        Promise.all([categoryId.json(), subcategoryList.json()])
    ).then((data) => {
      setCategories(data[0]);
      setSubcategoryList(data[1])
    })
    
    setShowSubCategory(true)
  },[]);
  
  function searchSubcategory(selectedCategoryId){
    const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${window.token}`,
      },
    };      
    fetch(`${window.url}/admin/fetchSubCategoryByCategory/${selectedCategoryId}`, requestOptions)
      .then((result)=>{
        result.json().then((resp)=>{
          setSubcategoryList(resp)
          setSelectedCategoryId(selectedCategoryId)
          sessionStorage.setItem('selectedCategoryId', selectedCategoryId)
          console.warn(sessionStorage)
          setShowSubCategory(true)
        })
    });
  }

  function handleRemove(id){
    const formData = new FormData();
        formData.append('id', id);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/admin/deleteSubCategory`, requestOptions)
      .then((response) => response.json())
      .then(function(data){        
        searchSubcategory(sessionStorage.selectedCategoryId)
      }) 
  }

  $('.deleteIcon').click(function(){
    $(this).parent().find('.catBoxDeleteWrap').fadeIn(300);
  });
  $('.sureToDelete, .cancel').click(function(){
    $('.catBoxDeleteWrap').fadeOut(300);
  });


  return (
    <>
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            
            <div className="caregoriesListing">
              <div className="cpwHead">
                <h2>All Subcategories</h2>
                <span className="headbttnsWrp">
                  <Link to='/admin/addsubcategories' className='addBttn'><FontAwesomeIcon icon="fa-plus" /></Link>
                </span>
              </div>

              <select value={sessionStorage.selectedCategoryId} className='changeCategory' onChange={(e)=>searchSubcategory(e.target.value)}>
                <option>Select Categories</option>
                {Object.values(Categories).map((category, key) => {
                    return (
                      <option value={category.id} key={key}>{category.title}</option>
                    );
                })}
              </select>
              
                {
                  showSubCategory?
                  <section className="subcatLIstMainWrap">
                    { subcategoryList.subcategories != null ?
                      <div className="subCatBoxContain">                        
                        {subcategoryList.subcategories.map((item, key) => (
                          <span className='subCatBox' id={item.id} key={key}>
                            {item.title} 
                            <button className='deleteIcon'><FontAwesomeIcon icon='fa-trash-can' /></button>
                            <Link className='editSubcat' to={'/admin/editsubcategories/'+item.id}><FontAwesomeIcon icon='fa-pencil' /></Link>
                            <div className="catBoxDeleteWrap">
                              <div className="confBox">
                                <h4>Sure to delete this Sub Category</h4>
                                <span>
                                  <button className='sureToDelete' onClick={() => handleRemove(item.id)}>Sure</button>
                                  <button className='cancel'>Cancel</button>
                                </span>
                              </div>
                            </div>
                          </span>
                        )) }
                      </div> : null
                    }
                  </section>:null
                }

              

            </div>

          </section>          
        </main>            
    </>
  );
}

export default Subcategories;
