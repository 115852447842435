import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Addsubcategories() {
  library.add(faCheckCircle, faTimes);

  const [title, setTitle] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('')
  const [animateSuccessAlert,setAnimateSuccessAlert] = useState("messageContain successMsg")

  const navigate = useNavigate();

  useEffect(()=>{
    const tokenString = sessionStorage.getItem('username');
    if(tokenString == null){
      navigate('/admin/login');
    }
    const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${window.token}`,
      },
    }; 
    Promise.all([
        fetch(`${window.url}/admin/fetchCategory`, requestOptions),
    ]).then(([categoryId])=>
        Promise.all([categoryId.json()])
    ).then((data) => {
      setCategoryId(data[0]);
      // console.log(data[0])
    })

  },[navigate]);

  function addCategory(){
    let item={title, selectedCategoryId}

    const formData = new FormData();    
    formData.append('title', item.title);
    formData.append('cat_id', item.selectedCategoryId);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
        body: formData
    };
    fetch(`${window.url}/admin/addSubCategory`, requestOptions)
    .then((response) => response.json())
    .then(function(data){ 
        console.log(data)
        if(data.msg === "sub_category_added"){
          setAnimateSuccessAlert("messageContain successMsg animate"); 
          setTimeout(function() {
            setAnimateSuccessAlert("messageContain successMsg"); 
          }, 2000); 
          setTitle('');            
          setCategoryId('');
        } 
    })
  }

  function closeAlrt(){
    setAnimateSuccessAlert("messageContain successMsg");
  }

  return (
    <>
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            <div className="addCategories">                
                <div className="cpwHead">
                    <h2>Add Sub Categories</h2>
                </div>
                <div className="addCatForm">
                    <select onChange={(e)=>setSelectedCategoryId(e.target.value)} className='addCategory'>
                        <option>Select Category</option>
                        {Object.values(categoryId).map((category,key) => {
                            return (
                                <option value={category.id} key={key}>{category.title}</option>
                            );
                        })}                         
                    </select>
                    <input value={title} onChange={(e)=>setTitle(e.target.value)} type="text" placeholder='Add Category' className='addCategory' />
                    <button onClick={addCategory}>Add</button>
                </div>
            </div>

          </section>          
        </main>   
        <div className={animateSuccessAlert}>
            <FontAwesomeIcon icon="fa-check-circle" className="mainIcon" /> Category Added Successfully <span className="closeMsg" onClick={closeAlrt}><FontAwesomeIcon icon="fa-times" /></span>
          </div>         
    </>
  );
}

export default Addsubcategories;
