import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Applogin() {
  const [animateSuccessAlert, setAnimateSuccessAlert] = useState("afterSuccessLogin")

  library.add(faArrowLeft, faEye, faEyeSlash);
  const navigate = useNavigate();

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  function login(){
    let item={username, password}

    const formData = new FormData();
    formData.append('username', item.username);
    formData.append('password', item.password);

    const requestOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${window.token}`,
        },
        body: formData
    };
    fetch(`${window.url}/user/login`, requestOptions)
    .then((response) => response.json())
    .then(function(data){
        if(data.error === 0){
          sessionStorage.setItem('user_id', data.user_id);
          setTimeout(function() {
            navigate('/');
          }, 1000);  
        }   
        if(data.error === 1){
          setTimeout(function() {
            setAnimateSuccessAlert("afterSuccessLogin animate"); 
            setTimeout(function() {
              setAnimateSuccessAlert("afterSuccessLogin"); 
            }, 3000); 
          }, 500); 
        } 
    })
  }

  const [loadingScreen, setLoadingScreen] = useState(false);

  useEffect(()=>{
    setLoadingScreen(true);
    setTimeout(function() {
      setLoadingScreen(false);
    }, 800);
  }, []); 

  const [isRevealPwd, setIsRevealPwd] = useState(false);



  return (
    <>

        {loadingScreen ? 
          <section className="loadingScreen">
            <span className="loader">
              <Oval
                ariaLabel="loading-indicator"
                height={100}
                width={100}
                strokeWidth={5}
                strokeWidthSecondary={2}
                color="#ffc659"
                secondaryColor="#f7f7f7"
              />
            </span>
          </section>:null
        }

        <section className="LoginPage Login">
          <div className="logMain">
              <h1>Login</h1>
              <div className="formMain">
                <input value={username} onChange={(e)=>setUsername(e.target.value)} type="text" placeholder="User Name" className="formFild" />
                <input value={password} onChange={(e)=>setPassword(e.target.value)} type={isRevealPwd ? "text" : "password"} placeholder="Password" className="formFild" />
                <FontAwesomeIcon icon={isRevealPwd ? 'fa-eye-slash' : 'fa-eye'} className="showHidePass" onClick={() => setIsRevealPwd(prevState => !prevState)} />
                <button onClick={login}>login now</button>
              </div>
              <p>No Account ? <Link to='/app/register'>Register Now</Link></p>
          </div>
          <Link className="backBttn" to='/'><FontAwesomeIcon icon="fa-arrow-left" /> Home</Link>
        </section>
        <div className={animateSuccessAlert}>Login Unsuccessful</div>
    </>
  );
}

export default Applogin;
