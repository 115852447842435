import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';

import { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Addstory() {
    library.add(faCheckCircle, faTimes);
    const [products,setProductsList]=useState([]);

    const [title, setTitle] = useState('');
    const [productId, setProductId] = useState('');
    const [storyVideo, setStoryVideo] = useState([]);
    const [thumbImage, setThumbImage] = useState([]);
    const [animateSuccessAlert,setAnimateSuccessAlert] = useState("messageContain successMsg");

    useEffect(()=>{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
          };
          
          fetch(`${window.url}/admin/fetchProducts`, requestOptions)
          .then((result)=>{
            result.json().then((resp)=>{
              setProductsList(resp)
            })
          })
    },[]);

    function addStory(){
        let item={title, productId, storyVideo, thumbImage}
        console.warn(item)

        const formData = new FormData();
        
        formData.append('title', item.title);
        formData.append('product_id', item.productId);
        formData.append('video', item.storyVideo);
        formData.append('thumb_image', item.thumbImage);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        fetch(`${window.url}/admin/addStories`, requestOptions)
        .then((response) => response.json())
        .then(function(data){ 
            console.log(data)  
            if(data.error === 0){
                setAnimateSuccessAlert("messageContain successMsg animate"); 
                setTimeout(function() {
                  setAnimateSuccessAlert("messageContain successMsg"); 
                }, 2000); 
            }  
        })
    }

    function closeAlrt(){
        setAnimateSuccessAlert("messageContain successMsg");
    }

  return (
    <>
        <main className='adminPanelMainWrap'>
            <Aside></Aside>
            <Rightelements></Rightelements>
            <section className="maindbBody">
                <Header></Header>
                <div className="completeProductWrapper">
                    <div className="cpwHead">
                        <h2>Add Story</h2>
                    </div> 

                    <div className="addProductWrap">
                        <input type="text" value={title} onChange={(e)=>setTitle(e.target.value)} className='usrInpt' placeholder='Story Title' />
                        <select className='usrInpt' onChange={(e)=>setProductId(e.target.value)}>
                            <option>Select Product</option>
                            {
                                products != null?
                                products.map((product,key)=>
                                <option value={product.id} key={key}>{product.title}</option>
                                ) :null
                            } 
                        </select>
                        <label className='textLabel'>Add Video</label>
                        <input type="file" accept="video/*" onChange={(e)=>setStoryVideo(e.target.files[0])} className='usrInpt' />
                        <label className='textLabel'>Add Poster Image</label>
                        <input type="file" accept="image/*" onChange={(e)=>setThumbImage(e.target.files[0])} className='usrInpt' />
                        <button onClick={addStory}>Add story</button>
                    </div>

                    <div className={animateSuccessAlert}>
                    <FontAwesomeIcon icon="fa-check-circle" className="mainIcon" /> Story Added Successfully <span className="closeMsg" onClick={closeAlrt}><FontAwesomeIcon icon="fa-times" /></span>
                    </div>

                </div> 
            </section>
        </main>     
    </>
  );
}

export default Addstory;
