import logo from '../../images/mainLogo.svg'
import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHeart, faBell, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Mainheader() {
    library.add(faHeart, faBell, faShoppingCart);
    const [wishListing, setWishListing] = useState('');
    const [shortAlert, setShortAlert] = useState(false);
    const navigate = useNavigate();

    const [showWishCount, setShowWishCount] = useState(false);
    const [cartListing, setCartListing] = useState('')

    useEffect(()=>{
      const tokenString = sessionStorage.getItem('user_id');   
      const formData = new FormData(); 
      formData.append('user_id', sessionStorage.getItem('user_id'));
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };
        const requestPostOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
        };
        Promise.all([
            fetch(`${window.url}/wishListOfUser/${tokenString}`, requestOptions),
            fetch(`${window.url}/cartDetails`, requestPostOptions)
        ]).then(([wishListing, cartListing])=>
            Promise.all([wishListing.json(), cartListing.json()])
        ).then((data) => {
          setWishListing(data[0]);
          setCartListing(data[1]);
        })
        if(tokenString != null){
          setShowWishCount(true)
        }else{setShowWishCount(false)}

    }, []); 

    function wishList(){
      const tokenString = sessionStorage.getItem('user_id');
      if(tokenString != null){
        navigate('/app/wishlist');
      }else {
        setShortAlert(true);
        setTimeout(function() {
          setShortAlert(false);
        }, 3000);
      }
    }

    function cartList(){
      const tokenString = sessionStorage.getItem('user_id');
      if(tokenString != null){
        navigate('/app/cart');
      }else {
        setShortAlert(true);
        setTimeout(function() {
          setShortAlert(false);
        }, 3000);
      }
    }
    return (
      <>
        <section className="mainHeader">
            <span className='logoWrp'><img src={logo} alt="" /></span>
            <span className="sideWigt">
                <button onClick={cartList}><FontAwesomeIcon icon="fa-shopping-cart" />
                  {showWishCount ?
                    <b>{cartListing.numberOfProduct}</b>: null
                  }                                
                </button>
                <button onClick={wishList}><FontAwesomeIcon icon="fa-heart" />
                  {showWishCount ?
                    <b>{wishListing.numberOfWishList}</b>: null
                  }                                
                </button>
                <Link><FontAwesomeIcon icon="fa-bell" /></Link>
            </span>
        </section>  
        <section className="searchBar">
            <div className="bar">Search</div>
        </section>

        {shortAlert ?
          <span className="errAlert">Login to continue</span>:null
        }
      </>
    );
  }
  
  export default Mainheader;
  