import Footer from "./components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Profile() {
    library.add( faArrowLeft, faBell );
    const navigate = useNavigate();

    const [discounts, setDiscounts] = useState('');
    useEffect(()=>{
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };      
        fetch(`${window.url}/offerList`, requestOptions)
          .then((result)=>{
            result.json().then((resp)=>{
                setDiscounts(resp);
            })
        });
    }, []); 

    function storeLastVisit(id){
        const tokenString = sessionStorage.getItem('user_id');
        const formData = new FormData();
        formData.append('user_id', tokenString);    
        formData.append('product_id', id);    
        const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        fetch(`${window.url}/addRecentViews`, requestOptions)
        .then((response) => response.json())
        .then(function(data){
            console.warn(data)
        })
      }


    return(
        <>
            <section className="productListHead">
                <Link className="backBttn"  onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
                <span className="rightWigt">
                    <button><FontAwesomeIcon icon="fa-bell" /></button>
                </span>
                <h4 className="wishListTxt">Offer</h4>
            </section> 

                    { discounts?.offerList != null ?
                        <div className="OfferListWrap">                   

                            {Object.values(discounts?.offerList).map((discount, key) => {
                                return (
                                <Link  onClick={() => storeLastVisit(discount?.id)} to={'/app/productdetails/'+discount?.id} className='offerBox' key={key}>
                                    <img src={discount?.discount_image} alt="" />
                                </Link>
                                );
                            })}
                        </div> :null
                    }

            <Footer></Footer>
        </>
    )
}
export default Profile;