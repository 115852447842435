import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';

import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Orders(){
    library.add(faArrowRight);

    const [orderList, setOrderList] = useState('')

    useEffect(()=>{
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };      
        fetch(`${window.url}/admin/orderList`, requestOptions)
          .then((result)=>{
            result.json().then((resp)=>{
                setOrderList(resp)
            })
        });
    },[]);

    return (
        <>
            <main className='adminPanelMainWrap'>
                <Aside></Aside>
                <Rightelements></Rightelements>
                <section className="maindbBody">
                    <Header></Header>
                    <div className="completeProductWrapper">
                        <div className="cpwHead">
                            <h2>All Orders</h2>
                        </div> 
                        { orderList?.orderList != null ?
                            <div className="completeOrderListWrap">
                                {Object.values(orderList?.orderList).reverse().map((orderBox, key) => {
                                    return (
                                        <div className="ordersListBox" key={key}>
                                            <h4>OrderBy : {orderBox?.name}
                                                <small>{orderBox?.address_line_1}, {orderBox?.address_line_2}, {orderBox?.pin}</small>
                                                <strong>{orderBox?.phone}</strong>
                                            </h4>
                                            <h5>Total Price: {orderBox?.total_price}
                                                <small>Qty: {orderBox?.total_qty}</small>
                                            </h5>
                                            <Link className='bttnMain' to={'/admin/orderdetails/'+orderBox?.id}><FontAwesomeIcon icon="fa-arrow-right" /></Link>
                                            <div className="orderTime">
                                                {orderBox?.created_at}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>:null
                        }

                    </div> 
                </section>
            </main>  
        </>
    )
}

export default Orders;