import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import $ from 'jquery'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faTrashCan, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Products() {
  library.add(faPlus, faTrashCan, faPencil);

  const [products,setProductsList]=useState([])

  function fetchProductList() {
    const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${window.token}`,
      },
    };
    
    fetch(`${window.url}/admin/fetchProducts`, requestOptions)
    .then((result)=>{
      result.json().then((resp)=>{
        setProductsList(resp)
      })
    })
  }

  useEffect(()=>{
    fetchProductList()
  },[]);

  function handleRemove(id) {
    const formData = new FormData();
        formData.append('id', id);
        const requestOptions = {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${window.token}`,
          },
          body: formData
      };
      fetch(`${window.url}/admin/deleteProduct`, requestOptions)
      .then((response) => response.json())
      .then(function(data){        
        fetchProductList()
      }) 
  }

  $('.delete').click(function(){
    $(this).parent().find('.deleteplBoxcontain').addClass('active');
    $(this).parent().nextAll().find('.deleteplBoxcontain').removeClass('active');
    $(this).parent().prevAll().find('.deleteplBoxcontain').removeClass('active');
  });

  $('.cancel').click(function(){
    $(this).parent().parent().find('.deleteplBoxcontain').removeClass('active');
  });

  const [searchTerm, setSearchTerm] = useState("");


    return (
      <>  
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            <div className="completeProductWrapper">
              <div className="cpwHead">
                <h2>Products</h2>
                <span className="headbttnsWrp">
                  <div className="searchBox">
                    <input type="text" className='searchInpt' placeholder='Search by product name' onChange={(event) => {setSearchTerm(event.currentTarget.value);}} />
                  </div>
                  <Link to='/admin/addproduct' className='addBttn'><FontAwesomeIcon icon="fa-plus" /></Link>
                </span>
              </div>
            
              <div className="productListWrap">                  
                {
                  products.filter((products) => {
                    if(searchTerm === ""){
                      return products
                    }else if (products.title.toLowerCase().includes(searchTerm.toLowerCase())){
                      return products
                    }
                  }).map((product,key)=>{
                    return(
                      <div className="plBox" key={key}>
                          <picture><img src={product.images} alt="" /></picture>
                          <h4>{product.title}
                            <small>{product.category}</small>
                          </h4>
                          <h5 className={product?.final_price != 0 ? 'price hide' : 'price show'}>Rs. {product.price}</h5>
                          <h5 className={product?.final_price == 0 ? 'price hide' : 'price show'}>Rs. {product.final_price}</h5>
                          
                          <div className="deleteplBoxcontain">
                            <p>Sure to delete</p>
                            <button className='sureDelete' onClick={() => handleRemove(product.id)}>Yes</button>
                            <button className='cancel'>No</button>
                          </div>
                          <Link to={'/admin/editproduct/'+product.id} className='edit'><FontAwesomeIcon icon="fa-pencil" /></Link>
                          <button className='delete'><FontAwesomeIcon icon="fa-solid fa-trash-can" /></button>
                        </div>
                    )})
                }
              </div>


            </div>

          </section>          
        </main>                    
      </>
    );
  }
  
  export default Products;