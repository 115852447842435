import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useState, useEffect  } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faSearch, faAngleRight, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuantityPicker } from 'react-qty-picker';
import { Oval } from 'react-loader-spinner';

function Cart() {
  library.add( faArrowLeft, faSearch, faAngleRight, faTrashCan );
  const navigate = useNavigate();
  const [cartList, setCartList] = useState('');
  const [shortAlert, setShortAlert] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [qtyValue, setQtyValue] = useState(1);
  const [showSearch, setShowSearch] = useState(false);

    function afterUpdateCartList(){
        const tokenString = sessionStorage.getItem('user_id');
        // console.log(tokenString)
        if(tokenString == null){
            navigate('/app/login');
        }
        const formData = new FormData();
        formData.append('user_id', tokenString);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        Promise.all([
            fetch(`${window.url}/cartDetails`, requestOptions)
        ]).then(([cartList])=>
            Promise.all([cartList.json()])
        ).then((data) => {
            setCartList(data[0]);
        })
    }

    const [loadingScreen, setLoadingScreen] = useState(false);

    useEffect(()=>{
        afterUpdateCartList()
        setLoadingScreen(true);
        setTimeout(function() {
          setLoadingScreen(false);
        }, 500);
    },[]); 

    function swipeTodelete(cart_id){        
        const formData = new FormData();
            formData.append('id', cart_id);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${window.token}`,
                },
                body: formData
            };
          fetch(`${window.url}/removeFromCart`, requestOptions)
          .then((response) => response.json())
          .then(function(data){   
            if(data.error === 0){
                setShortAlert(true);
                setTimeout(function() {
                  setShortAlert(false);
                }, 2500);
                afterUpdateCartList()
            }         
          }) 
    }


    function qtyPicker(qtyValue, cart_id){
        setQtyValue(qtyValue);
        const formData = new FormData();
        formData.append('qty', qtyValue);
        formData.append('cart_id', cart_id);

        const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
        };
        
        fetch(`${window.url}/editCart`, requestOptions)
        .then((response) => response.json())
        .then(function(data){
          if(data.error === 0){
            afterUpdateCartList()  
          }  
        }) 
    }

    function search(){
        setShowSearch(true)
    }
    function hideSearch(){
        setShowSearch(false)
    }


  return (
    <>

    {loadingScreen ? 
      <section className="loadingScreen">
        <span className="loader">
            <Oval
              ariaLabel="loading-indicator"
              height={100}
              width={100}
              strokeWidth={5}
              strokeWidthSecondary={2}
              color="#ffc659"
              secondaryColor="#f7f7f7"
            />
        </span>
      </section>:null
    }

        {showSearch ? 
        <section className="searchWrapSingle">
            <span className='closeSearch' onClick={hideSearch}><FontAwesomeIcon icon="fa-arrow-left" /></span>
            <input type="text" placeholder='Search...' className='searchBox' onChange={(event) => {setSearchTerm(event.currentTarget.value);}} />
        </section>:null
        }

        <section className="productListHead">
            <Link className="backBttn"  onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
            <span className="rightWigt">
                <button onClick={search}><FontAwesomeIcon icon="fa-search" /></button>
            </span>
            <h4 className="wishListTxt">Cart</h4>
        </section>   

        <h4 className="noItems">No Item Found in Cart</h4>

        { cartList?.cartDetails != null ?
            <div className="wishListTotalWrap">
                {
                  cartList.cartDetails.filter((cartDetails) => {
                    if(searchTerm === ""){
                      return cartDetails
                    }else if (cartDetails.title.toLowerCase().includes(searchTerm.toLowerCase())){
                      return cartDetails
                    }
                  }).map((CartBox,key)=>{
                return (
                        <div className="listBox" key={key}>
                            <picture>
                              <Link to={'/app/productdetails/'+CartBox?.id}>
                              <img src={CartBox.images} alt="" />
                              </Link>
                            </picture>                            
                            <h4>{CartBox.title}<small>
                                    <b className={CartBox?.discount >0 ? "stripped" : "notStripped"}>Rs : <strong>{CartBox.price}</strong></b>    
                                    <b className={CartBox?.discount >0 ? "" : "hide"}>Rs : <strong>{CartBox.final_price}</strong></b>    
                                </small></h4>
                            <button className="DelectSlot" onClick={() => swipeTodelete(CartBox.cart_id)}><FontAwesomeIcon icon="fa-trash-can" /></button>
                            <QuantityPicker min={1} value={CartBox.qty} onChange={(qtyValue) => qtyPicker(qtyValue, CartBox.cart_id)} />
                        </div> 
                    )})
                }
            </div>: null
        }

        {shortAlert ?
          <span className="errAlert wishRemove">Removed</span>:null
        }
        
        <div className="cartTotalDes">
            <h4>Total Price : <strong>{cartList.total}</strong></h4> 
            <Link to="/app/checkout" className={cartList.total >0 ? "cBttn show" : "cBttn"}>Checkout <FontAwesomeIcon icon="fa-angle-right" /></Link>
        </div>
    </>
  );
}

export default Cart;
