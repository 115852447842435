import Footer from "./components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Profile() {
    library.add( faArrowLeft, faArrowRight );
    const navigate = useNavigate();

    const [OrderLists, setOrderlists] = useState({})

    useEffect(()=>{
        const tokenString = sessionStorage.getItem('user_id');
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };
        Promise.all([
            fetch(`${window.url}/orderList/${tokenString}`, requestOptions)
        ]).then(([OrderLists])=>
            Promise.all([OrderLists.json()])
        ).then((data) => {
            setOrderlists(data[0]);
            // console.log(data[0])
        }) 
    },[]);


    return(
        <>
            <section className="productListHead">
                <Link className="backBttn"  onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
                <h4 className="wishListTxt">My Orderlist</h4>
            </section> 

            { OrderLists?.orderList != null ?
                <section className="wishListTotalWrap">
                {Object.values(OrderLists?.orderList).map((orderBox, key) => {
                    return (
                    <div className="listBox osList" key={key}>   
                        <div className="orderDetailsInfo">
                            <h3>Order Details : </h3>
                            <p>Name : {orderBox?.details?.name}</p>
                            <p>Phone : {orderBox?.details?.phone} &nbsp; &nbsp; | &nbsp; &nbsp; Products : {orderBox?.details?.total_qty}</p>
                            <p>
                                Address : {orderBox?.details?.address_line_1} {orderBox?.details?.address_line_2} <b>Pin : {orderBox?.details?.pin}</b>
                            </p>
                        </div>
                        <section className="allGrupedProducts">
                            {orderBox?.products?.map((product, key) => (
                                <div className="productBoxMain" key={key}>
                                    <Link to={'/app/productdetails/'+product?.id}><img src={product?.images} key={key} alt="" /></Link>
                                    <article>
                                        <h4>{product?.title} ({product?.qty})
                                            <small>Product Price: {product?.price}</small>
                                        </h4>
                                        <h3>Total Price : {product?.total_price}</h3>
                                    </article>
                                </div>
                            ))}
                        </section>
                        <Link to={'/app/seeallorders/'+orderBox?.details?.id} className={orderBox?.details?.total_qty >3 ? 'more show' : 'more'}>See All</Link>
                        <div className="totalPrice">
                            <p>{orderBox?.details?.created_at}</p>
                            <p>Total Price : {orderBox?.details?.total_price}</p>
                        </div>
                    </div>
                    );
                })}
                </section>: null
            }

            <Footer></Footer>
        </>
    )
}
export default Profile;