import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';

import { Link } from 'react-router-dom';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faPencil, faTrashCan, faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useEffect, useState } from "react";
function Storylist() {
    library.add(faPlus, faPencil, faTrashCan, faCheckCircle, faTimes);

    const [stories, setStories] = useState('')
    const [animateSuccessAlert,setAnimateSuccessAlert] = useState("messageContain successMsg")

    function afterDelete(){
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
        };              
        fetch(`${window.url}/stories`, requestOptions)
            .then((result)=>{
            result.json().then((resp)=>{
                setStories(resp)
            })
        });

    }
    
    useEffect(()=>{
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };          
        fetch(`${window.url}/stories`, requestOptions)
            .then((result)=>{
            result.json().then((resp)=>{
                setStories(resp)
            })
        });

        afterDelete()
  
    },[]);

    function handleRemove(id){
        const formData = new FormData();
            formData.append('id', id);
            const requestOptions = {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${window.token}`,
              },
              body: formData
          };
          fetch(`${window.url}/admin/deleteStories`, requestOptions)
          .then((response) => response.json())
          .then(function(data){  
            if(data.error === 0){
                setAnimateSuccessAlert("messageContain successMsg animate"); 
                setTimeout(function() {
                  setAnimateSuccessAlert("messageContain successMsg"); 
                }, 2000); 
            }      
            afterDelete()
          }) 
    }

    function closeAlrt(){
        setAnimateSuccessAlert("messageContain successMsg");
    }
    
  return (
    <>
        <main className='adminPanelMainWrap'>
            <Aside></Aside>
            <Rightelements></Rightelements>
            <section className="maindbBody">
                <Header></Header>
                <div className="completeProductWrapper">
                    <div className="cpwHead">
                        <h2>Stories</h2>
                        <span className="headbttnsWrp">
                            <Link to="/admin/addstory" className='addBttn'><FontAwesomeIcon icon="fa-plus" /></Link>
                        </span>
                    </div> 

                    
                    { stories.stories != null ?
                    <div className="storyListWrapAdmin">
                        {Object.values(stories.stories).map((story, key) => {
                            return (
                            <div className="storyBox" key={key}>
                                <Link className='edit' to={'/admin/editstory/'+story.id}><FontAwesomeIcon icon="fa-pencil" /></Link>
                                <button id={story.id} className='delete' onClick={() => handleRemove(story.id)}><FontAwesomeIcon icon="fa-trash-can" /></button>
                                <video src={story.video} controls disablePictureInPicture controlsList="nodownload noremoteplayback noplaybackrate"></video>                    
                            </div>
                            );
                        })} 
                    </div>: null
                    }

                </div> 
                <div className={animateSuccessAlert}>
                <FontAwesomeIcon icon="fa-check-circle" className="mainIcon" /> Story Removed Successfully <span className="closeMsg" onClick={closeAlrt}><FontAwesomeIcon icon="fa-times" /></span>
                </div>
            </section>
        </main>     
    </>
  );
}

export default Storylist;
