import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import Select from 'react-select';
function Addcoupon() {
    const navigate = useNavigate();

    const [categories, setCategories] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [discountAmount, setDiscountAmount] = useState();

    useEffect(()=>{ 
        const requestOptions = {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
        };            
        Promise.all([
            fetch(`${window.url}/admin/fetchCategory`, requestOptions),
        ]).then(([categories])=>
            Promise.all([categories.json()])
        ).then((data) => {
          setCategories(data[0]);
        })
    },[]);

    function addCouponList(){

        const formData = new FormData();    
        formData.append('cat_id', selectedCategoryId);
        formData.append('start_date', startDate);
        formData.append('end_date', endDate);
        formData.append('coupon', couponCode);
        formData.append('discount', discountAmount);
        formData.append('lessAmmountFlag', 0);
    
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        fetch(`${window.url}/admin/addCoupon`, requestOptions)
        .then((response) => response.json())
        .then(function(data){ 
            console.log(data)
          if(data.error === 0){
            setTimeout(function() {
                navigate(-1);
            }, 500); 
          } 
        })
      }

      const handelSelect = (e) => {
        setSelectedCategoryId(Array.isArray(e) ? e.map(x => x.values) : []);
      }

  return (
    <>
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            
            <div className="completeProductWrapper">
              <div className="cpwHead">
                <h2>Add Coupon</h2>
              </div>

              <div className="addProductWrap">
                <label className='textLabel'>Select Category</label> 

                <select className="usrInpt" onChange={handelSelect} multiple>
                    <option>Select Category</option>    
                    {Object.values(categories).map((category, key) => {
                      return (
                        <option values={category.id} key={key}>{category.title}</option>
                      );
                    })}
                </select>   

                {/* <Select className="dropdown" placeholder="Select Option" value={categories.filter(obj => selectedCategoryId.includes(obj.id))} options={categories} onChange={handelSelect} isMulti isClearable /> */}

                <label className='textLabel'>Start Date</label> 
                <input type="date" className='usrInpt' onChange={(e)=>setStartDate(e.target.value)}/>     
                <label className='textLabel'>End Date</label> 
                <input type="date" className='usrInpt' onChange={(e)=>setEndDate(e.target.value)}/>   
                <label className='textLabel'>Coupon Code</label> 
                <input type="text" className='usrInpt' onChange={(e)=>setCouponCode(e.target.value)}/> 
                <label className='textLabel'>Discount Amount</label> 
                <input type="number" className='usrInpt' onChange={(e)=>setDiscountAmount(e.target.value)}/> 
                <button onClick={addCouponList}>Add Coupon</button>
              </div>




            </div>

          </section>          
        </main>            
    </>
  );
}

export default Addcoupon;
