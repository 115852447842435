import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Oval } from 'react-loader-spinner';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { QuantityPicker } from 'react-qty-picker';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faShoppingCart, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Productdetails() {
  library.add(faArrowLeft, faShoppingCart, faHeart);
    const { id } = useParams();
    const [productDetails, setProductDetails] = useState('');
    const [shortAlert, setShortAlert] = useState(false);
    const [addedAlert, setAddedAlert] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);
    const navigate = useNavigate();

    function addedWishList() {
      const tokenString = sessionStorage.getItem('user_id');

      const formData = new FormData();
        formData.append('id', id);        
        if(tokenString != null){
          formData.append('user_id', sessionStorage.getItem('user_id'));
        }
        const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
        };
        Promise.all([
            fetch(`${window.url}/productDetails`, requestOptions)
        ]).then(([productDetails])=>
            Promise.all([productDetails.json()])
        ).then((data) => {
          setProductDetails(data[0]);
        })
    }

    const[cartDetails, setCartDetails] = useState('');
    useEffect(()=>{
      addedWishList() ; 
      addedTocart();
      setLoadingScreen(true);
      setTimeout(function() {
        setLoadingScreen(false);
      }, 500);
    }, []); 

    function addToWishlist(id){
      const formData = new FormData();
      formData.append('product_id', id);
      formData.append('user_id', sessionStorage.getItem('user_id'));
      
      const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
        body: formData
      };
      
      fetch(`${window.url}/addToWishList`, requestOptions)
      .then((response) => response.json())
      .then(function(data){
        if(data.msg === 'success'){
          addedWishList()
        } 
        if(data.error === 1){
          setShortAlert(true);
          setTimeout(function() {
            setShortAlert(false);
          }, 3000);
        } 
      })      
    }

    const [qtyValue, setQtyValue] = useState(1);

    const qtyPicker = (qtyValue) =>{
      setQtyValue(qtyValue)
    }

    const [addCart, setAddcart] = useState(false)

    function addCartBttn(){
      const tokenString = sessionStorage.getItem('user_id');

      if(tokenString != null){

        const formData = new FormData();
        formData.append('product_id', id);
        formData.append('user_id', tokenString);
        formData.append('qty', qtyValue);

        const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
        };
        
        fetch(`${window.url}/addToCart`, requestOptions)
        .then((response) => response.json())
        .then(function(data){
          // console.warn(data) 
          if(data.msg === 'success'){
            setAddcart(true);
            setTimeout(function() {
              setAddcart(false);
            }, 3000);
            addedTocart()
          } 
          if(data.msg === 'already added to cart'){
            setAddedAlert(true);
            setTimeout(function() {
              setAddedAlert(false);
            }, 3000);
          } 
        }) 

      }else {
        setShortAlert(true);
        setTimeout(function() {
          setShortAlert(false);
        }, 3000);
      }
    }

    function cartList(){
      const tokenString = sessionStorage.getItem('user_id');
      if(tokenString != null){
        navigate('/app/cart');
      }else{
        setShortAlert(true);
        setTimeout(function() {
          setShortAlert(false);
        }, 3000);
      }
    }

    function addedTocart(){ 
      const tokenString = sessionStorage.getItem('user_id');   
      const formData = new FormData(); 
        if(tokenString != null){
          formData.append('user_id', sessionStorage.getItem('user_id'));
        }
        const requestOptions = {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${window.token}`,
          },
          body: formData
        };
        Promise.all([
            fetch(`${window.url}/cartDetails`, requestOptions)
        ]).then(([cartDetails])=>
            Promise.all([cartDetails.json()])
        ).then((data) => {
          setCartDetails(data[0]);
        })
    }


    return (
      <>  

      {loadingScreen ? 
        <section className="loadingScreen">
          <span className="loader">
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            strokeWidthSecondary={2}
            color="#ffc659"
            secondaryColor="#f7f7f7"
          />
          </span>
        </section>:null
      }
      
      <section className="productListHead detailsHead">
        <Link className="backBttn" onClick={() => navigate(-1)}><FontAwesomeIcon icon="fa-arrow-left" /></Link>
        <span className="rightWigt">
          <button onClick={cartList}><FontAwesomeIcon icon="fa-shopping-cart" />
            <b>{cartDetails.numberOfProduct}</b>
          </button>
        </span>
      </section> 
      { 
        productDetails.productDetails != null ?
        <section className="CompleteProductDetailsWrap">
          <picture><img src={productDetails.productDetails.images} alt="" /></picture>
          <article>
            <button className={productDetails.productDetails?.wishlisht === 1 ? "addWish added" : "addWish"} onClick={() => addToWishlist(productDetails.productDetails?.id)}><FontAwesomeIcon icon="fa-heart" /></button>
            <h1>{productDetails.productDetails.title}
              <small>
                <b className={productDetails.productDetails.discount >0 ? "stripped" : "notStripped"}>RS : {productDetails.productDetails.price}</b>
                <b className={productDetails.productDetails.discount >0 ? "" : "hide"}>RS : {productDetails.productDetails.final_price}</b>
              </small>
            </h1>
            <p>{productDetails.productDetails.description}</p>
            <QuantityPicker min={1} value={qtyValue} onChange={qtyPicker} />
            <span className="addBttnsWrap">
              <button className="bttn" onClick={addCartBttn}>Add To Cart</button>
              <Link className="bttn" onClick={addCartBttn} to='/app/checkout'>Buy Now</Link>
            </span>
          </article>
        </section>:null
      }

      {shortAlert ?
        <span className="errAlert">Login to continue</span>:null
      }

      {addedAlert ?
        <span className="errAlert">Already Added</span>:null
      }

      {addCart ?
        <span className="errAlert wishRemove">Cart Added</span>:null
      }
      </>
    );
  }
  
  export default Productdetails;
  