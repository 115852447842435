import Aside from './Aside';
import Rightelements from './Rightelements';
import Header from './Header';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
function Addcategories() {

    const [title, setTitle] = useState('');

  const navigate = useNavigate();
  useEffect(()=>{
    const tokenString = sessionStorage.getItem('username');
    if(tokenString == null){
      navigate('/admin/login');
    }

  },[navigate]);

  function addCategory(){
    let item={title}

    const formData = new FormData();    
    formData.append('title', item.title);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${window.token}`,
        },
        body: formData
    };
    fetch(`${window.url}/admin/addCategory`, requestOptions)
    .then((response) => response.json())
    .then(function(data){ 
        console.log(data)
        if(data.msg === 'category_added'){
            navigate('/admin/categories');
        } 
    })
  }

  return (
    <>
        <main className='adminPanelMainWrap'>
          <Aside></Aside>
          <Rightelements></Rightelements>
          
          <section className="maindbBody">
            <Header></Header>
            <div className="addCategories">                
                <div className="cpwHead">
                    <h2>Add Categories</h2>
                </div>
                <div className="addCatForm">
                    <input value={title} onChange={(e)=>setTitle(e.target.value)} type="text" placeholder='Add Category' className='addCategory' />
                    <button onClick={addCategory}>Add</button>
                </div>
            </div>

          </section>          
        </main>            
    </>
  );
}

export default Addcategories;
