import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Recentproducts() {
    library.add( faPlus );
    // const navigate = useNavigate();
    const [recentProductsBlocks, setRecentProductsBlocks] = useState('');

    useEffect(()=>{
        const tokenString = sessionStorage.getItem('user_id');

        const formData = new FormData();
        formData.append('user_id', tokenString);    
        const requestOptions = {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${window.token}`,
            },
            body: formData
        };
        Promise.all([
            fetch(`${window.url}/fetchRecentViews`, requestOptions),
        ]).then(([recentProductsBlocks])=>
            Promise.all([recentProductsBlocks.json()])
        ).then((data) => {
            setRecentProductsBlocks(data[0]);
            // console.warn(data[0])
        })
    }, []); 



    return(
        <>
            { recentProductsBlocks?.recentViews != null ?
                <div className="recentViewedProducts">
                    <section className="TrendingSlot">
                        <h4>Recent Visit</h4>
                        <div className="tpScroll">
                        {Object.values(recentProductsBlocks?.recentViews).reverse().map((recentProduct, key) => {
                            return ( 
                                <div className="tpBox" key={key}>
                                <picture>
                                    <Link to={'/app/productdetails/'+recentProduct?.id}>
                                    <img src={recentProduct.images} alt="" />
                                    </Link>
                                </picture>
                                <h3>
                                    <small>{recentProduct?.title}</small>
                                    ₹ {recentProduct?.price}
                                </h3>  
                                <Link className="productDetailsbttn" to={'/app/productdetails/'+recentProduct?.id}><FontAwesomeIcon icon='fa-plus'/></Link>
                            </div>
                            );
                        })}
                        </div>
                    </section>
                </div>:null
            }
        </>
    )
}
export default Recentproducts;